import commonUtil from "../common";
import {
  NETWORK_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const createContact = contact => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/contacts`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, contact, headers);
}

export const deleteContactById = contactId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/contacts/${contactId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getContactById = contactId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/contacts/${contactId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getContacts = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/contacts?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getContactStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/contacts/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const updateContactById = (contactId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/contacts/${contactId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}

export const uploadContacts = (fileFormData) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/contacts/upload`;
  let headers = {};

  return commonUtil.httpclient(method, url, fileFormData, headers);
}


export const getOutlookContacts = async (accessToken) => {
  let method = "GET";
  let url = `https://graph.microsoft.com/v1.0/me/contacts`;
  let headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${accessToken}`
  };
  const response = await fetch(url, { method, headers });
  return response.json().then(result => (result));
}

export const exportContacts = (contactIds) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/contacts/export?${decodeURIComponent(contactIds)}`;
  let headers = {};

  return commonUtil.httpclient(method, url, null, headers);
}

export const getContactUploadTemplate=()=>{
  let method="GET";
  let url=`${SERVER_URL}${SERVER_API_ALL}/contacts/upload/template`;
  let headers={};
  
  return commonUtil.httpclient(method,url,null,headers);
}