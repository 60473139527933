import commonUtil from "../common";
import {
  SERVER_API_ALL,
  USER_SERVER_URL,
} from "../config";
const SERVER_URL = USER_SERVER_URL;

const createUser = user => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/users`;
  let headers = {
    "Content-Type": "application/json",
  };

  return commonUtil.httpclient(method, url, user, headers);
};

const getUserById = userId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/users/` + userId;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, userId, headers);
}

const updateUserById = (userId, user) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/users/${userId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, user, headers);
};

const deleteUserById = userId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/users/${userId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, userId, headers);
};

const updateUserLogoById = (userId, formData) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/users/${userId}/logo`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, formData, headers);
}

const getUsers = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/users?${queries}`;
  let params = { spinnerOn: true };
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

const getUserStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/users/stats?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, { spinnerOn: false }, headers);
};

const getMyUser = () => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/user`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
};

export {
  createUser, deleteUserById, getMyUser, getUserById,
  getUserStats, getUsers, updateUserById, updateUserLogoById
};

