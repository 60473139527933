import commonUtil from "../common";
import {
    USER_SERVER_URL,
    SERVER_API_ALL
} from "../config";
const SERVER_URL = USER_SERVER_URL;

export const createUserBio = userBio => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/user-bios`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, userBio, headers);
}

export const deleteUserBioById = userBioId => {
    let method = "DELETE";
    let url = `${SERVER_URL}${SERVER_API_ALL}/user-bios/${userBioId}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}

export const getUserBioById = userBioId => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/user-bios/${userBioId}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}

export const getUserBios = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/user-bios?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}

export const getUserBioStats = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/user-bios/stats?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}

export const updateUserBioById = (userBioId, params) => {
    let method = "PATCH";
    let url = `${SERVER_URL}${SERVER_API_ALL}/user-bios/${userBioId}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, params, headers);
}

export const getMyUserBio = () => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/user-bio`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, null, headers);
}

export const getUserBioByUserId = userId => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/user-bios/users/${userId}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, null, headers);
}

export const getUserBioJobTitles = () => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/user-bios/job-titles`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, null, headers);
}





