import React from "react";
import AuthContext from "../../../../AuthContext";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import mtzApis from "../../../../services";

const { chatService, userService, companyService } = mtzApis;

function Invites({ onUpdate, newRoomInviteEvent }) {
    let [myInviteds, setMyInviteds] = React.useState([]);
    const me = React.useContext(AuthContext);

    React.useEffect(() => {
        let mounted = true;
        mtzApis.startSpinningIcon();
        (async () => {
            myInviteds = await chatService.getRoomInvites(new URLSearchParams(`inviteeIds=${me.userId}`));
            if (myInviteds) {
                myInviteds = await loadInviteInfo(myInviteds);
                if (mounted) setMyInviteds(myInviteds);
            }
        })();
        mtzApis.stopSpinningIcon();
        return () => mounted = false;
    }, []);

    // invite event
    React.useEffect(() => {
        let mounted = true;
        (async () => {
            if (newRoomInviteEvent && newRoomInviteEvent.resource) {
                if (newRoomInviteEvent.action === 'CREATED') {
                    let newInvite = newRoomInviteEvent.resource;
                    newInvite = (await loadInviteInfo([newInvite]))[0];

                    if (myInviteds) {
                        setMyInviteds([newInvite, ...myInviteds]);
                    }
                } else {
                    myInviteds = myInviteds.filter(inv => inv.id !== newRoomInviteEvent.resource.id);
                    setMyInviteds(myInviteds);
                }
            }
        })();
        return () => mounted = false;
    }, [newRoomInviteEvent]);

    const loadInviteInfo = async invites => {
        let users = await userService.getUsers(new URLSearchParams(`userIds=${invites.map(inv => inv.inviterId)}`));
        let map = {};
        users.forEach(u => map[u.id] = u);
        invites.forEach(inv => inv.inviter = map[inv.inviterId]);
        return invites;
    }

    const acceptInvite = async (invite, accepted) => {
        mtzApis.startSpinningIcon();
        let updated = await chatService.updateRoomInviteById(invite.id, { _accept: accepted })
        mtzApis.stopSpinningIcon();

        if (updated) {
            myInviteds = myInviteds.filter(i => i.id !== updated.id);
            setMyInviteds(myInviteds);
        }
    };

    return (
        <>
            {
                (
                    !myInviteds[0] ?
                        '' :
                        <div className="mtz-card">
                            <h5 className="mtz-h5">Chat Requests:</h5>

                            {
                                myInviteds.map(inv => (
                                    <div key={inv.id} className="d-flex align-items-center mtz-gap-4">
                                        <span className="flex-fill">
                                            {
                                                (!inv.meta || !inv.meta.type) &&
                                                <span className="text-break">- New message from {inv.inviter.firstname} {inv.inviter.lastname}</span>
                                            }
                                        </span>
                                        <button onClick={() => acceptInvite(inv, false)} className="btn btn-sm text-danger">reject</button>
                                        |
                                        <button onClick={() => acceptInvite(inv, true)} className="btn btn-sm text-primary">accept</button>
                                    </div>
                                ))
                            }
                        </div>
                )
            }
        </>
    );
}

const stateToProps = (state) => ({
    newRoomInviteEvent: state.roomInvite_topic.new_uievent,
});

const dispatchToProps = (dispatch) => ({
});

const InvitesWrapper = connect(stateToProps, dispatchToProps)(withRouter(Invites));

export default InvitesWrapper;