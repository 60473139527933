import React from 'react';

import googleOneTap from 'google-one-tap';
import { NavLink, withRouter } from 'react-router-dom';
import HomeSearchBoxNew from '../../components/shared2/HomeSearchBoxNew';
import mtzApis from "../../services";
import { GOOGLE_CLIENT_ID } from '../../services/config';
import './NonLoginHeader.css';
const { userService, commonUtil } = mtzApis;

function NonLoginHeader({ history }) {
    let [mobileSearchBoxOnFocus, setMobileSearchBoxOnFocus] = React.useState(false);

    return (
        <>
            <div className='bg-white py-1 d-none d-xl-flex align-items-center border justify-content-center'>
                <div className='text-center' style={{ width: '320px' }}>
                    <NavLink to="/">
                        <img src="/assets/images/Logos.png" height="48px" />
                    </NavLink>
                </div>

                <NonLoginMenu />

                <div className={'d-xl-none d-flex'}>
                    <button className='btn btn-sm'>
                        <span className='fa fa-bars'></span>
                    </button>
                </div>
            </div>

            <div className='bg-white d-xl-none d-flex flex-column py-1 border align-items-center justify-content-center'>
                <div className='w-100 d-flex mtz-gap-8 align-items-center justify-content-center'>
                    {
                        !mobileSearchBoxOnFocus &&
                        <>
                            <button className="btn btn-sm navbar-toggler text-secondary" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className='fa fa-bars'></span>
                            </button>

                            {/* <NavLink to="/">
                                <img src="/assets/images/Logos_1.png" height="30px" />
                            </NavLink> */}
                        </>
                    }

                    <div className='w-100 p-1 d-xl-none d-inline-block flex-grow-1'>
                        <HomeSearchBoxNew onFocus={() => setMobileSearchBoxOnFocus(true)} onBlur={() => setMobileSearchBoxOnFocus(false)} />
                    </div>
                </div>

                <div className='collapse mt-2 bg-light mtz-rounded-16 mtz-p-16' id='navbarSupportedContent'>
                    <NonLoginMenu />
                </div>
            </div>
        </>
    );
}

function NonLoginMenu() {

    const renderMenuItemsXl = () => (
        <div className='d-flex mtz-gap-20 align-items-center' style={{ maxWidth: '800px' }}>
            <div className='d-flex mtz-gap-20'>
                {
                    (window.location.pathname.startsWith('/search')) ?
                        <div className='d-none d-xl-inline-block searchbox'>
                            <HomeSearchBoxNew />
                        </div> :
                        <>
                            <NavLink className='mx-2 btn font-weight-bold mtz-h6 text-nowrap' to='/search'>Search</NavLink>
                            <NavLink className='mx-2 btn font-weight-bold mtz-h6 text-nowrap' to='/about'>About Us</NavLink>
                            <NavLink className='mx-2 btn font-weight-bold mtz-h6 text-nowrap' to='/pricing'>Pricing</NavLink>
                            <NavLink className='mx-2 btn font-weight-bold mtz-h6 text-nowrap' to='/contact'>Contact Us</NavLink>

                        </>
                }
            </div>

            <div className='d-flex mtz-gap-4 justify-content-center align-items-center' style={{ width: '420px' }}>
                <NavLink className='btn btn-primary mx-2 btn font-weight-bold mtz-h6 text-nowrap' to='/login'>Sign In</NavLink>
                <NavLink className='mx-2 btn btn-outline-primary font-weight-bold mtz-h6 text-nowrap' to='/register'>Join Free</NavLink>
            </div>
        </div>
    );

    const renderMenuItemsXs = () => (
        <div className='d-flex mtz-gap-20'>
            <div className='d-flex mtz-gap-4 flex-column'>
                {
                    (window.location.pathname.startsWith('/search')) &&
                    <div className='d-none d-xl-inline-block searchbox'>
                        <HomeSearchBoxNew />
                    </div>
                }

                {
                    !(window.location.pathname.startsWith('/search')) &&
                    <>
                        <NavLink to='/search'>Search</NavLink>
                        <NavLink to='/about'>About Us</NavLink>
                        <NavLink to='/about'>Pricing</NavLink>
                        <NavLink to='/contact'>Contact Us</NavLink>

                        <div className='d-flex mtz-gap-4 justify-content-end align-items-center' style={{ width: '320px' }}>
                            <NavLink className='btn btn-outline-primary btn-sm' to='/login'>Sign In</NavLink>
                            <NavLink className='btn btn-primary btn-sm' to='/register'>Join Free</NavLink>
                        </div>
                    </>
                }
            </div>
        </div>
    );

    return (
        <>
            <div className='d-flex flex-column d-lg-none'>
                {renderMenuItemsXs()}
            </div>

            <div className='d-lg-flex d-none align-items-center'>
                {renderMenuItemsXl()}
            </div>
        </>
    );
};

const options = {
    client_id: GOOGLE_CLIENT_ID, // required
    auto_select: false, // optional
    cancel_on_tap_outside: false, // optional
    context: 'signin', // optional
};
googleOneTap(options, (response) => {
    // Send response to server
    console.log(response);
});

export default withRouter(NonLoginHeader);