import commonUtil from "../common";
import { NETWORK_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const createCompanyGroupSponsor = (companyGroupSponsor) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-sponsors`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, companyGroupSponsor, headers);
};

export const deleteCompanyGroupSponsorById = (companyGroupSponsorId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-sponsors/${companyGroupSponsorId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCompanyGroupSponsorById = (companyGroupSponsorId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-sponsors/${companyGroupSponsorId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCompanyGroupSponsorsByGroupId = (companyGroupId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-sponsors/group/${companyGroupId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCompanyGroupSponsors = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-sponsors?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCompanyGroupSponsorStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-sponsors/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const updateCompanyGroupSponsorById = (companyGroupSponsorId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-sponsors/${companyGroupSponsorId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

export const getMyCompanyGroupSponsors = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-sponsor?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getMyCompanyGroupSponsorStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-sponsor/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};