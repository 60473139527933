import React from "react";
import ReactDOM from "react-dom";


// import { hotjar } from 'react-hotjar';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import '../node_modules/jquery/dist/jquery.min.js';
import { Provider } from "react-redux";
import App from "./App";
import store from './redux/store';

const PROT = window.location.protocol;
const HOST = window.location.hostname;
const URL = window.location.href;
let NEWURL = URL;

// if (window.location.hostname === 'mytradezone.com')
//   if (window.location.pathname !== '/mtz-admin' && window.location.pathname !== '/grantee') {
//     hotjar.initialize(3119479, 6);
//     if (hotjar.initialized())
//       console.log('HotJar works!');
//   }

if (HOST !== 'localhost' && !HOST.startsWith('192.168')) {
  if (PROT === 'http:')
    NEWURL = NEWURL.replace(PROT, 'https:');
}

if (HOST !== 'localhost') {
  if (HOST.startsWith('www.'))
    NEWURL = NEWURL.replace("www.", "");
}

if (NEWURL !== URL) {
  window.location.href = NEWURL;
} else {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>
    , document.getElementById("root"));
}

// // Identify the user
// hotjar.identify('USER_ID', { userProperty: 'value' });

// // Add an event
// hotjar.event('button-click');

// // Update SPA state
// hotjar.stateChange('/my/page');

// // Check if Hotjar has been initialized before calling its methods
// if (hotjar.initialized()) {
//   hotjar.identify('USER_ID', { userProperty: 'value' });
// }