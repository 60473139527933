import commonUtil from "../common";
import {
  NETWORK_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const createResourceLimit = resourceLimit => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/resource-limits`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, resourceLimit, headers);
}

export const updateResourceLimitById = (resourceLimitId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/resource-limits/${resourceLimitId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}

export const getResourceLimitById = resourceLimitId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/resource-limits/${resourceLimitId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getResourceLimits = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/resource-limits?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getResourceLimitStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/resource-limits/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const deleteResourceLimitById = id => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/resource-limits/${id}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}