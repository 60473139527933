import commonUtil from "../common";
import {
  SERVER_API_ALL,
  USER_SERVER_URL,
} from "../config";
const SERVER_URL = USER_SERVER_URL;

export const createResourceSync = params => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/resource-syncs`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

export const getResourceSyncById = id => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/resource-syncs/${id}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getResourceSyncs = q => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/resource-syncs?${q.toString()}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
};