import React from 'react';
import ReactDOM from "react-dom";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

function ConfirmPopup({ title, onYes, onNo, yesTitle, noTitle }) {
	React.useEffect(() => {
		if (!onYes) {
			throw new Error('Missing "onYes" method as attr');
		}

		if (!onNo) {
			throw new Error('Missing "onNo" method as attr');
		}

		if (!title) {
			throw new Error('Missing "title" method as attr');
		}
	}, []);

	return (
		<>
			<Dialog open={true}>
				<DialogTitle>{title}</DialogTitle>
				<DialogActions>
					<button className="btn 	btn-outline-primary mtz-btn " onClick={onNo}>{!noTitle ? 'No' : noTitle}</button>
					<button className="btn btn-primary mtz-btn text-white" onClick={onYes}>{yesTitle ? yesTitle : 'Yes'}</button>
				</DialogActions>
			</Dialog>
		</>
	);
}

function createMtzConfirm(title, yesTitle, noTitle) {
	return new Promise((res, rej) => {
		let answer = null;
		let div = document.createElement('div');
		let root = document.getElementById('root');
		root.appendChild(div);

		const onYes = () => {
			ReactDOM.unmountComponentAtNode(div);
			answer = true;
			res(answer);
		};
		const onNo = () => {
			ReactDOM.unmountComponentAtNode(div);
			answer = false;
			res(answer);
		};

		ReactDOM.render(<ConfirmPopup title={title} yesTitle={yesTitle} noTitle={noTitle} onYes={onYes} onNo={onNo} />, div);
	});
};

export default createMtzConfirm;