import commonUtil from "../common";
import { NETWORK_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const getCompanyGroupPostLikes = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-likes?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCompanyGroupPostLikeStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-likes/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCompanyGroupPostLikeById = (companyGroupPostLikeId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-likes/${companyGroupPostLikeId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const createCompanyGroupPostLike = (like) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-likes`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, like, headers);
};

export const deleteCompanyGroupPostLikeById = (companyGroupPostLikeId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-likes/${companyGroupPostLikeId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const deleteCompanyGroupPostLikes = (queries) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-likes?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const updateCompanyGroupPostLikeById = (companyGroupPostLikeId, like) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-likes/${companyGroupPostLikeId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, like, headers);
};
