import commonUtil from "../common";
import { NETWORK_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const getCompanyGroupTypes = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-types?${decodeURIComponent(queries)}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCompanyGroupTypeStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-types/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCompanyGroupTypeById = (companyGroupTypeId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-types/` + companyGroupTypeId;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, companyGroupTypeId, headers);
};

export const deleteCompanyGroupTypeById = (companyGroupTypeId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-types/${companyGroupTypeId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const createCompanyGroupType = (newCompanyGroupType) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-types`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, newCompanyGroupType, headers);
};

export const updateCompanyGroupTypeById = (companyGroupTypeId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-types/${companyGroupTypeId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};
