import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import React from 'react';
import ImageUploading from "react-images-uploading";

export default function ImageUploader({ onChange, maxNumber }) {
	const [images, setImages] = React.useState([]);
	const onImagesChanged = (imageList, addUpdateIndex) => {
		setImages(imageList);
		if (onChange)
			onChange(imageList);
	};

	return (
		<React.Fragment>
			<ImageUploading
				multiple
				value={images}
				onChange={onImagesChanged}
				maxNumber={maxNumber || 5}
				dataURLKey="data_url"
			>
				{({
					imageList,
					onImageUpload,
					onImageRemoveAll,
					onImageUpdate,
					onImageRemove,
					isDragging,
					dragProps
				}) => (
					<Card className="border" elevation={0}>
						<CardHeader
							title={<div className='mtz-h5'>Upload product images:</div>}
						/>

						<CardContent className="py-1">
							<div className="upload__image-wrapper">
								<div className="my-2">
									<button className="btn btn-sm btn-outline-secondary mtz-btn" onClick={onImageRemoveAll}>Remove all images</button>
									&nbsp;
									<button className="btn btn-sm btn-primary mtz-btn"
										style={isDragging ? { color: "red" } : null}
										onClick={onImageUpload}
										{...dragProps}
									>
										Click or Drop here
									</button>
								</div>

								{imageList.map((image, index) => (
									<div key={index} className="my-2">
										<img src={image.data_url} alt="" width="100" />
										<div className="d-flex mtz-gap-4">
											<button className="btn text-primary" onClick={() => onImageUpdate(index)}>Edit</button>
											<button className="btn text-secondary" onClick={() => onImageRemove(index)}>Remove</button>
										</div>
									</div>
								))}
							</div>
						</CardContent>
					</Card>
				)}
			</ImageUploading>
		</React.Fragment>
	);
}