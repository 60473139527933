import commonUtil from "../common";
import {
    NETWORK_SERVER_URL,
    SERVER_API_ALL,
} from "../config";
const SERVER_URL = NETWORK_SERVER_URL;
export const createRewardPointRedeem = params => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/reward-point-redeems`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, params, headers);
}
export const getRewardPointRedeems = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/reward-point-redeems?${queries}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, null, headers);
}
export const getRewardPointRedeemById = id => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/reward-point-redeems/${id}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, null, headers);
}
export const getRewardPointRedeemStats = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/reward-point-redeems/stats?${queries}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, null, headers);
}