import commonUtil from "../common";
import {
    NETWORK_SERVER_URL,
    SERVER_API_ALL,
} from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const getMtzPointRates = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-point-rates?${queries}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, null, headers);
}
export const updateMtzPointRateById = (mtzPointRateId, params) => {
    let method = "PATCH";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-point-rates/${mtzPointRateId}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, params, headers);
}