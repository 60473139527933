import commonUtil from "../common";
import { NETWORK_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const createCGMemberPlan = (companyGroupMemberPlan) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-plans`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, companyGroupMemberPlan, headers);
};

export const deleteCGMemberPlanById = (companyGroupMemberPlanId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-plans/${companyGroupMemberPlanId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const updateCGMemberPlanById = (companyGroupMemberPlanId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-plans/${companyGroupMemberPlanId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

export const getCGMemberPlanById = (companyGroupMemberPlanId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-plans/${companyGroupMemberPlanId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGMemberPlans = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-plans?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGMemberPlanStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-plans/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};