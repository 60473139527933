import commonUtil from "../common";
import { NETWORK_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const createCompanyGroupSponsorAdTemplate = (companyGroupSponsorAdTemplate) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-sponsor-ad-templates`;
  let headers = {};
  return commonUtil.httpclient(method, url, companyGroupSponsorAdTemplate, headers);
};

export const deleteCompanyGroupSponsorAdTemplateById = (companyGroupSponsorAdTemplateId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-sponsor-ad-templates/${companyGroupSponsorAdTemplateId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCompanyGroupSponsorAdTemplateById = (companyGroupSponsorAdTemplateId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-sponsor-ad-templates/${companyGroupSponsorAdTemplateId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCompanyGroupSponsorAdTemplates = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-sponsor-ad-templates?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const getCompanyGroupSponsorAdTemplateStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-sponsor-ad-templates/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const updateCompanyGroupSponsorAdTemplateById = (
  companyGroupSponsorAdTemplateId,
  params
) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-sponsor-ad-templates/${companyGroupSponsorAdTemplateId}`;
  let headers = {};
  return commonUtil.httpclient(method, url, params, headers);
};
