import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import mtzApis from "../../../services";
import AuthContext from "../../../AuthContext";
import "./style.css";

const { companyService, networkService } = mtzApis;

class MyCRMPage extends React.Component {
    constructor(props) {
        const params = new URLSearchParams(window.location.search);
        const welcome = params.get("welcome") || false;

        super(props);
        this.state = {
            myCompany: null,
            trackingCodeStats: null,
            notesStats: null,
            contactStats: null,
            contactGroupStats: null,
            formBuilderStats: null,
            emailSchemasStats: null,
            emailMarketingStats: null,
            rfiStats: null
        }
    }

    componentDidMount() {
        this.init();
    }

    init = async () => {
        let me = this.props.me || {};

        let myCompany = await companyService.getMyCompany();
        if (myCompany) {
            let rfiStats = await companyService.getRfiStats(new URLSearchParams(`companyIds=${myCompany.id}`));
            this.setState({ myCompany })

            let trackingCodeStats = await networkService.getTrackingCodeStats(new URLSearchParams(`ownerIds=${me.userId}`));
            if (trackingCodeStats) {
                this.setState({ trackingCodeStats })
            }

            let notesStats = await companyService.getCompanyNoteStats(new URLSearchParams(`ownerIds=${me.userId}`));
            if (notesStats) {
                this.setState({ notesStats })
            }

            let contactStats = await networkService.getContactStats(new URLSearchParams(`ownerIds=${me.userId}`));
            if (contactStats) {
                this.setState({ contactStats })
            }

            let contactGroupStats = await networkService.getContactGroupStats(new URLSearchParams(``));
            if (contactGroupStats) {
                this.setState({ contactGroupStats })
            }

            let formBuilderStats = await networkService.getFormBuilderStats(new URLSearchParams(`ownerIds=${me.userId}`));
            if (formBuilderStats) {
                this.setState({ formBuilderStats })
            }

            // let emailSchemasStats = await networkService.getEmailSchemaStats(new URLSearchParams(`ownerIds=${me.userId}`));
            // if (emailSchemasStats) {
            //     this.setState({ emailSchemasStats })
            // }

            let emailMarketingStats = await networkService.getEmailMarketingCampaignStats(new URLSearchParams(`ownerIds=${me.userId}`));
            if (emailMarketingStats) {
                this.setState({ emailMarketingStats })
            }

            if (rfiStats) {
                this.setState({ rfiStats })

            }
        }
    }

    render() {
        let { myCompany, trackingCodeStats, notesStats, contactStats,
            contactGroupStats, formBuilderStats, emailSchemasStats,
            emailMarketingStats, rfiStats } = this.state;
        const params = new URLSearchParams(window.location.search);

        let avatarUrl = myCompany && myCompany.logoUrl;
        avatarUrl = avatarUrl &&
            (
                avatarUrl.startsWith("http") ?
                    avatarUrl :
                    companyService.getBackendHost() + avatarUrl
            );

        return (
            <Box>
                <Grid container spacing={1}>
                    <Grid item md={6} xs={12}>
                        <Box style={{
                            border: "1px solid #EDEDED",
                            background: "#FFFFFF",
                            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.05)",
                            borderRadius: "16px",
                            textAlign: "center",
                            padding: "24px 16px",
                        }}
                            component="button"
                            className="w-100 crm-button"
                            onClick={() => window.location.href = "/me/crm/tracking-codes"}
                        >
                            <div style={{
                                fontFamily: "Satoshi",
                                fontStyle: "normal",
                                fontWeight: 900,
                                fontSize: "20px",
                                lineHeight: "27px",
                                letterSpacing: "0.01em",
                                color: "#3772FF"
                            }}>
                                {trackingCodeStats && trackingCodeStats.all ? trackingCodeStats.all.count : 0}
                            </div>
                            <div style={{
                                fontFamily: "Satoshi",
                                fontStyle: "normal",
                                fontWeight: 700,
                                fontSize: "16px",
                                lineHeight: "22px",
                                letterSpacing: "0.01em",
                                color: "#2B2B2B",
                                paddingTop: "24px"
                            }}>
                                Tracking Codes
                            </div>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box style={{
                            border: "1px solid #EDEDED",
                            background: "#FFFFFF",
                            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.05)",
                            borderRadius: "16px",
                            textAlign: "center",
                            padding: "24px 16px",
                        }}
                            component="button"
                            className="w-100 crm-button"
                            onClick={() => window.location.href = "/me/crm/company-notes"}
                        >
                            <div style={{
                                fontFamily: "Satoshi",
                                fontStyle: "normal",
                                fontWeight: 900,
                                fontSize: "20px",
                                lineHeight: "27px",
                                letterSpacing: "0.01em",
                                color: "#3772FF"
                            }}>
                                {notesStats && notesStats.all ? notesStats.all.count : 0}
                            </div>
                            <div style={{
                                fontFamily: "Satoshi",
                                fontStyle: "normal",
                                fontWeight: 700,
                                fontSize: "16px",
                                lineHeight: "22px",
                                letterSpacing: "0.01em",
                                color: "#2B2B2B",
                                paddingTop: "24px"
                            }}>
                                Company Notes
                            </div>
                        </Box>
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <Box style={{
                            border: "1px solid #EDEDED",
                            background: "#FFFFFF",
                            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.05)",
                            borderRadius: "16px",
                            textAlign: "center",
                            padding: "24px 16px",
                        }}
                            component="button"
                            className="w-100 crm-button"
                            onClick={() => window.location.href = "/me/crm/contacts"}
                        >
                            <div style={{
                                fontFamily: "Satoshi",
                                fontStyle: "normal",
                                fontWeight: 900,
                                fontSize: "20px",
                                lineHeight: "27px",
                                letterSpacing: "0.01em",
                                color: "#3772FF"
                            }}>
                                {contactStats && contactStats.all ? contactStats.all.count : 0}
                            </div>
                            <div style={{
                                fontFamily: "Satoshi",
                                fontStyle: "normal",
                                fontWeight: 700,
                                fontSize: "16px",
                                lineHeight: "22px",
                                letterSpacing: "0.01em",
                                color: "#2B2B2B",
                                paddingTop: "24px"
                            }}>
                                Contacts
                            </div>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box style={{
                            border: "1px solid #EDEDED",
                            background: "#FFFFFF",
                            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.05)",
                            borderRadius: "16px",
                            textAlign: "center",
                            padding: "24px 16px",
                        }}
                            component="button"
                            className="w-100 crm-button"
                            onClick={() => window.location.href = "/me/crm/contact-groups"}
                        >
                            <div style={{
                                fontFamily: "Satoshi",
                                fontStyle: "normal",
                                fontWeight: 900,
                                fontSize: "20px",
                                lineHeight: "27px",
                                letterSpacing: "0.01em",
                                color: "#3772FF"
                            }}>
                                {contactGroupStats && contactGroupStats.all ? contactGroupStats.all.count : 0}
                            </div>
                            <div style={{
                                fontFamily: "Satoshi",
                                fontStyle: "normal",
                                fontWeight: 700,
                                fontSize: "16px",
                                lineHeight: "22px",
                                letterSpacing: "0.01em",
                                color: "#2B2B2B",
                                paddingTop: "24px"
                            }}>
                                Contact Groups
                            </div>
                        </Box>
                    </Grid>

                    <Grid item md={4} xs={12}>
                        <Box style={{
                            border: "1px solid #EDEDED",
                            background: "#FFFFFF",
                            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.05)",
                            borderRadius: "16px",
                            textAlign: "center",
                            padding: "24px 16px",
                        }}
                            component="button"
                            className="w-100 crm-button"
                            onClick={() => window.location.href = "/me/crm/form-builders"}
                        >
                            <div style={{
                                fontFamily: "Satoshi",
                                fontStyle: "normal",
                                fontWeight: 900,
                                fontSize: "20px",
                                lineHeight: "27px",
                                letterSpacing: "0.01em",
                                color: "#3772FF"
                            }}>
                                {formBuilderStats && formBuilderStats.all ? formBuilderStats.all.count : 0}
                            </div>
                            <div style={{
                                fontFamily: "Satoshi",
                                fontStyle: "normal",
                                fontWeight: 700,
                                fontSize: "16px",
                                lineHeight: "22px",
                                letterSpacing: "0.01em",
                                color: "#2B2B2B",
                                paddingTop: "24px"
                            }}>
                                Form Builder
                            </div>
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Box style={{
                            border: "1px solid #EDEDED",
                            background: "#FFFFFF",
                            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.05)",
                            borderRadius: "16px",
                            textAlign: "center",
                            padding: "24px 16px",
                        }}
                            component="button"
                            className="w-100 crm-button"
                            onClick={() => window.location.href = "/me/crm/email-schemas"}
                        >
                            <div style={{
                                fontFamily: "Satoshi",
                                fontStyle: "normal",
                                fontWeight: 900,
                                fontSize: "20px",
                                lineHeight: "27px",
                                letterSpacing: "0.01em",
                                color: "#3772FF"
                            }}>
                                {emailSchemasStats && emailSchemasStats.all ? emailSchemasStats.all.count : 0}
                            </div>
                            <div style={{
                                fontFamily: "Satoshi",
                                fontStyle: "normal",
                                fontWeight: 700,
                                fontSize: "16px",
                                lineHeight: "22px",
                                letterSpacing: "0.01em",
                                color: "#2B2B2B",
                                paddingTop: "24px"
                            }}>
                                Email Schemas
                            </div>
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Box style={{
                            border: "1px solid #EDEDED",
                            background: "#FFFFFF",
                            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.05)",
                            borderRadius: "16px",
                            textAlign: "center",
                            padding: "24px 16px",
                        }}
                            component="button"
                            className="w-100 crm-button"
                            onClick={() => window.location.href = "/me/crm/email-marketing"}
                        >
                            <div style={{
                                fontFamily: "Satoshi",
                                fontStyle: "normal",
                                fontWeight: 900,
                                fontSize: "20px",
                                lineHeight: "27px",
                                letterSpacing: "0.01em",
                                color: "#3772FF"
                            }}>
                                {emailMarketingStats && emailMarketingStats.all ? emailMarketingStats.all.count : 0}
                            </div>
                            <div style={{
                                fontFamily: "Satoshi",
                                fontStyle: "normal",
                                fontWeight: 700,
                                fontSize: "16px",
                                lineHeight: "22px",
                                letterSpacing: "0.01em",
                                color: "#2B2B2B",
                                paddingTop: "24px"
                            }}>
                                Email Marketing
                            </div>
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Box style={{
                            border: "1px solid #EDEDED",
                            background: "#FFFFFF",
                            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.05)",
                            borderRadius: "16px",
                            textAlign: "center",
                            padding: "24px 16px",
                        }}
                            component="button"
                            className="w-100 crm-button"
                            onClick={() => window.location.href = "/me/crm/rfis"}
                        >
                            <div style={{
                                fontFamily: "Satoshi",
                                fontStyle: "normal",
                                fontWeight: 900,
                                fontSize: "20px",
                                lineHeight: "27px",
                                letterSpacing: "0.01em",
                                color: "#3772FF"
                            }}>
                                {rfiStats && rfiStats.all ? rfiStats.all.count : 0}
                            </div>
                            <div style={{
                                fontFamily: "Satoshi",
                                fontStyle: "normal",
                                fontWeight: 700,
                                fontSize: "16px",
                                lineHeight: "22px",
                                letterSpacing: "0.01em",
                                color: "#2B2B2B",
                                paddingTop: "24px"
                            }}>
                                RFIs
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

export default props => (
    <AuthContext.Consumer>
        {
            value => (
                <MyCRMPage {...props} me={value} />
            )
        }
    </AuthContext.Consumer>
)
