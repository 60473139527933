import commonUtil from "../common";
import { NETWORK_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const createCGEvent = (cgEvent) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-events`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, cgEvent, headers);
};

export const deleteCGEventById = (cgEventId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-events/${cgEventId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGEventById = (cgEventId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-events/${cgEventId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGEvents = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-events?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGEventStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-events/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const updateCGEventById = (cgEventId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-events/${cgEventId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

export const uploadCGEventFile = (cgEventId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-events/${cgEventId}/_uploadFile`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

export const getCGEventTimes = () => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-events/times`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGEventTimeZones = () => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-events/time-zones`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};