import {
  createAllKeywordSearchStat,
  getAllKeywordSearchStats,
  getAllKeywordSearchStatStats,
} from "./allKeywordSearchStatApi";

import {
  getCompanyCreateStats,
  getCompanyCreateStatStats,
} from "./companyCreateStatApi";

import {
  getCompanyLogoCreateStats,
  getCompanyLogoCreateStatStats,
} from "./companyLogoCreateStatApi";

import {
  getCompanyBannerCreateStats,
  getCompanyBannerCreateStatStats,
} from "./companyBannerCreateStatApi";

import {
  getCompanyKeywordSearchStats,
  getCompanyKeywordSearchStatStats,
} from "./companyKeywordSearchStatApi";

import {
  getCompanyViewStats,
  getCompanyViewStatStats,
} from "./companyViewStatApi";

import {
  getCompanyViewStatStatsUserData,
  getCompanyViewStatsUserData,
} from "./companyViewStatUserDataApi";

import {
  createCompanyWebsiteClickStat,
  getCompanyWebsiteClickStats,
  getCompanyWebsiteClickStatStats,
} from "./companyWebsiteClickStatApi";

import {
  getConnectionCreateStats,
  getConnectionCreateStatStats,
} from "./connectionCreateStatApi";

import {
  getContactCreateStats,
  getContactCreateStatStats,
} from "./contactCreateStatApi";

import {
  getFollowCreateStats,
  getFollowCreateStatStats,
} from "./followCreateStatApi";

import {
  getMemberCreateStats,
  getMemberCreateStatStats,
} from "./memberCreateStatApi";

import {
  getMessageCreateStats,
  getMessageCreateStatStats,
} from "./messageCreateStatApi";

import {
  getProductCreateStats,
  getProductCreateStatStats,
} from "./productCreateStatApi";

import {
  getProductKeywordSearchStats,
  getProductKeywordSearchStatStats,
} from "./productKeywordSearchStatApi";

import {
  getProductViewStats,
  getProductViewStatStats,
} from "./productViewStatApi";

import {
  getRegistrationStats,
  getRegistrationStatStats,
} from "./registrationStatApi";

import {
  createUserCreateStatReader,
  deleteUserCreateStatReaderById,
  getUserCreateStatReaderById,
  getUserCreateStatReaders,
  getUserCreateStatReaderStats
} from './userCreateStatReaderApi';

import {
  getUserCreateStatReaderData,
  getUserCreateStatReaderDataStats
} from './userCreateStatReaderDataApi';

import {
  createUserConfirmStatReader,
  deleteUserConfirmStatReaderById,
  getUserConfirmStatReaderById,
  getUserConfirmStatReaders,
  getUserConfirmStatReaderStats
} from './userConfirmStatReaderApi';

import {
  getUserConfirmStatReaderData,
  getUserConfirmStatReaderDataStats
} from './userConfirmStatReaderDataApi';

import {
  getRegistrationConfirmStats,
  getRegistrationConfirmStatStats,
} from "./registrationConfirmStatApi";

import {
  getResetPasswordStats,
  getResetPasswordStatStats,
} from "./resetPasswordStatApi";

import {
  getRoomCreateStats,
  getRoomCreateStatStats,
} from "./roomCreateStatApi";

import {
  getUserLoginStats,
  getUserLoginStatStats,
} from "./userLoginStatApi";

import {
  getUserSessionDistinctStats,
  getUserSessionDistinctStatStats,
  getUserSessionStats,
  getUserSessionStatStats
} from "./userSessionStatApi";

import {
  getProfileAppearStats,
  getProfileAppearStatStats
} from "./profileAppearStatApi";

import {
  getCompanyGroupCreateStats,
  getCompanyGroupCreateStatStats
} from "./companyGroupCreateStatApi";

import {
  getFeedCreateStats,
  getFeedCreateStatStats
} from "./feedCreateStatApi";

import {
  getCompanyGroupPostCreateStats,
  getCompanyGroupPostCreateStatStats
} from "./companyGroupPostCreateStatApi";

import {
  getCompanyGroupMemberCreateStats,
  getCompanyGroupMemberCreateStatStats
} from "./companyGroupMemberCreateStatApi";

import {
  getProductViewStatsUserData,
  getProductViewStatUserDataStats
} from "./productViewStatUserDataApi";

import {
  getProfileAppearStatsUserData,
  getProfileAppearStatUserDataStats
} from "./profileAppearStatUserDataApi";

import * as companyAdKeywordClickStatApi from "./companyAdKeywordClickStatApi";
import * as companyAdKeywordClickStatUserDataApi from "./companyAdKeywordClickStatUserDataApi";
import * as companyAdKeywordCreateStatApi from "./companyAdKeywordCreateStatApi";
import * as companyAdKeywordImpressionStatApi from "./companyAdKeywordImpressionStatApi";
import * as companyAdKeywordImpressionStatUserDataApi from "./companyAdKeywordImpressionStatUserDataApi";
import * as companyGroupEventCreateStatApi from "./companyGroupEventCreateStatApi";
import * as companyGroupEventKeyworSearchStatApi from "./companyGroupEventKeyworSearchStatApi";
import * as companyGroupEventViewStatApi from "./companyGroupEventViewStatApi";
import * as companyGroupEventViewStatUserDataApi from "./companyGroupEventViewStatUserDataApi";
import * as companyGroupKeywordSearchStatApi from "./companyGroupKeywordSearchStatApi";
import * as companyGroupMemberInviteStatApi from "./companyGroupMemberInviteStatApi";
import * as companyGroupMemberJoinStatApi from "./companyGroupMemberJoinStatApi";
import * as companyGroupMemberRequestStatApi from "./companyGroupMemberRequestStatApi";
import * as companyGroupViewStatApi from "./companyGroupViewStatApi";
import * as companyGroupViewStatUserDataApi from "./companyGroupViewStatUserDataApi";
import * as connectionAcceptStatApi from "./connectionAcceptStatApi";
import * as emailCampaignApi from "./emailCampaignApi";
import * as emailCampaignGroupApi from "./emailCampaignGroupApi";
import * as emailCampaignTriggerApi from "./emailCampaignTriggerApi";
import * as rfqViewStatApi from "./rfqViewStatApi";
import * as rfqViewStatUserDataApi from "./rfqViewStatUserDataApi";
import * as sponsorshipSubscriptionCreateStatApi from "./sponsorshipSubscriptionCreateStatApi";
import * as syncedCompanyApi from "./syncedCompanyApi";
import * as syncedUserApi from "./syncedUserApi";
import * as triggerSendConfirmApi from "./triggerSendConfirmApi";
import * as userResourceStatApi from "./userResourceStatApi";
import * as cgSponsorClickApi from "./cgSponsorClickApi"
import * as cgMemberClickApi from "./cgMemberClickApi"


export default {
  createAllKeywordSearchStat,
  getAllKeywordSearchStats,
  getAllKeywordSearchStatStats,

  getCompanyCreateStats,
  getCompanyCreateStatStats,

  getCompanyLogoCreateStats,
  getCompanyLogoCreateStatStats,

  getCompanyBannerCreateStats,
  getCompanyBannerCreateStatStats,

  getCompanyKeywordSearchStats,
  getCompanyKeywordSearchStatStats,

  getCompanyViewStats,
  getCompanyViewStatStats,

  getCompanyViewStatsUserData,
  getCompanyViewStatStatsUserData,

  createCompanyWebsiteClickStat,
  getCompanyWebsiteClickStats,
  getCompanyWebsiteClickStatStats,

  getConnectionCreateStats,
  getConnectionCreateStatStats,

  getContactCreateStats,
  getContactCreateStatStats,

  getFollowCreateStats,
  getFollowCreateStatStats,

  getMemberCreateStats,
  getMemberCreateStatStats,

  getMessageCreateStats,
  getMessageCreateStatStats,

  getProductCreateStats,
  getProductCreateStatStats,

  getProductKeywordSearchStats,
  getProductKeywordSearchStatStats,

  getProductViewStats,
  getProductViewStatStats,

  getRegistrationStats,
  getRegistrationStatStats,

  createUserCreateStatReader,
  getUserCreateStatReaderById,
  deleteUserCreateStatReaderById,
  getUserCreateStatReaders,
  getUserCreateStatReaderStats,

  getUserCreateStatReaderData,
  getUserCreateStatReaderDataStats,

  createUserConfirmStatReader,
  getUserConfirmStatReaderById,
  deleteUserConfirmStatReaderById,
  getUserConfirmStatReaders,
  getUserConfirmStatReaderStats,

  getUserConfirmStatReaderData,
  getUserConfirmStatReaderDataStats,

  getRegistrationConfirmStats,
  getRegistrationConfirmStatStats,

  getResetPasswordStats,
  getResetPasswordStatStats,

  getRoomCreateStats,
  getRoomCreateStatStats,

  getUserLoginStats,
  getUserLoginStatStats,

  getUserSessionStats,
  getUserSessionStatStats,
  getUserSessionDistinctStats,
  getUserSessionDistinctStatStats,

  getProfileAppearStats,
  getProfileAppearStatStats,

  getCompanyGroupMemberCreateStatStats,
  getCompanyGroupMemberCreateStats,

  getCompanyGroupPostCreateStatStats,
  getCompanyGroupPostCreateStats,

  getFeedCreateStatStats,
  getFeedCreateStats,

  getCompanyGroupCreateStatStats,
  getCompanyGroupCreateStats,

  getProductViewStatUserDataStats,
  getProductViewStatsUserData,

  getProfileAppearStatUserDataStats,
  getProfileAppearStatsUserData,

  ...rfqViewStatApi,
  ...rfqViewStatUserDataApi,

  ...companyGroupEventCreateStatApi,
  ...companyGroupEventKeyworSearchStatApi,
  ...companyGroupEventViewStatApi,
  ...companyGroupKeywordSearchStatApi,
  ...companyGroupMemberInviteStatApi,
  ...companyGroupMemberJoinStatApi,
  ...companyGroupViewStatApi,
  ...companyGroupViewStatUserDataApi,
  ...companyGroupEventViewStatUserDataApi,
  ...userResourceStatApi,
  ...cgSponsorClickApi,
  ...cgMemberClickApi,
  ...sponsorshipSubscriptionCreateStatApi,
  ...emailCampaignApi,
  ...triggerSendConfirmApi,
  ...emailCampaignGroupApi,
  ...emailCampaignTriggerApi,
  ...syncedCompanyApi,
  ...syncedUserApi,
  ...companyAdKeywordClickStatApi,
  ...companyAdKeywordClickStatUserDataApi,
  ...companyAdKeywordCreateStatApi,
  ...companyAdKeywordImpressionStatApi,
  ...companyAdKeywordImpressionStatUserDataApi,
  ...companyGroupMemberRequestStatApi,
  ...connectionAcceptStatApi
};