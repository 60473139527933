import React from 'react';



import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import mtzApis from '../../../services';

const { companyService, networkService } = mtzApis;

function HomeSearchBoxNew({ onFocus, onBlur }) {
  let params = new URLSearchParams(window.location.search);
  let [suggestions, setSuggestions] = React.useState({});
  let [k, setK] = React.useState(params.get("keyword"));
  let [searchAnchor, setSearchAnchor] = React.useState(null);
  let [focused, setFocused] = React.useState(false);
  const searchBox = React.useRef();
  const searchRes = React.useRef();

  React.useEffect(() => {
    const searchTimeout = setTimeout(() => autoComplete(k), 100);

    const handleClickOutside = document.addEventListener("mousedown", e => {
      if (searchRes && searchRes.current && !searchRes.current.contains(e.target))
        if (searchBox && searchBox.current && !searchBox.current.contains(e.target))
          setFocused(false);

      if (searchBox && searchBox.current && searchBox.current.contains(e.target))
        setFocused(true);
    });

    return () => {
      clearTimeout(searchTimeout);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [k, window.location.search]);

  const onSearch = e => {
    e.preventDefault();
    if (!k || k.trim() === '') {
      return;
    }
    k = k.trim()
    k = encodeURIComponent(k);
    window.location.href = `/search?keyword=${k}&type=${(new URLSearchParams(window.location.search).get("type")) || 'COMPANY'}`;
  };

  const autoComplete = async key => {
    if (key && key.trim() != '') {
      key = encodeURIComponent(key);
      let ajaxes = [
        companyService.getCompanySearches(new URLSearchParams(`keyword=${key}&skip=0&limit=4&deactivated=false`)),
        companyService.getCompanySearchStats(new URLSearchParams(`keyword=${key}&deactivated=false`)),
        companyService.getProductSearches(new URLSearchParams(`keyword=${key}&skip=0&limit=4&deactivated=false`)),
        companyService.getProductSearchStats(new URLSearchParams(`keyword=${key}&deactivated=false`)),
        companyService.getRfqSearches(new URLSearchParams(`keyword=${key}&skip=0&limit=4`)),
        companyService.getRfqSearchStats(new URLSearchParams(`keyword=${key}&skip=0&limit=4`)),
        networkService.getCompanyGroupSearches(new URLSearchParams(`keyword=${key}&skip=0&limit=4&deactivated=false`)),
        networkService.getCompanyGroupSearchStats(new URLSearchParams(`keyword=${key}&skip=0&limit=4&deactivated=false`))
      ];

      let data = await Promise.allSettled(ajaxes);
      data = data.map(d => d.value);
      let [companySearches, companySearchStats, productSearches, productSearchStats, rfqSearches, rfqSearchStats, companyGroupSearches, companyGroupSearchStats] = data;

      if (productSearches) {
        let companyIds = productSearches.map(s => s.product.companyId);
        let companies = await companyService.getCompanies(new URLSearchParams(`companyIds=${companyIds}`));
        if (companies) {
          let compMap = {};
          companies.forEach(c => compMap[c.id] = c);
          productSearches.forEach(s => s.company = compMap[s.product.companyId] || {});
        }
      }

      suggestions = { ...suggestions, companySearches, companySearchStats, productSearchStats, productSearches, rfqSearches, rfqSearchStats, companyGroupSearches, companyGroupSearchStats };
      setSuggestions(suggestions);
    } else {
      setTimeout(() => setSuggestions({}), 500);
    }
  };

  return (
    <form
      className='d-flex mtz-gap-20 w-100 align-items-center' onSubmit={onSearch}>
      <div className='w-100'>
        <input ref={searchBox}
          className='form-control mtz-rounded-16'
          placeholder="Search companies, products, and more..."
          onChange={e => setK(e.target.value)}
          style={{ height: '40px' }}
          value={k || ''}
        />

        <div ref={searchRes} style={{ width: searchBox && searchBox.current ? searchBox.current.clientWidth : 'auto' }}
          className={'text-left vh-75 overflow-auto shadow my-1 mtz-rounded-16 position-absolute bg-white' + (k && focused ? '' : ' d-none')}>
          {
            Object.keys(suggestions).includes('companySearches') &&
            suggestions.companySearchStats && suggestions.companySearchStats.all && suggestions.companySearchStats.all.count !== 0 &&
            <div className='m-2'>
              <div display="flex">
                <div>
                  <h6><b>Companies found: {suggestions.companySearchStats && suggestions.companySearchStats.all ? suggestions.companySearchStats.all.count : 0}</b></h6>
                </div>
                <div ml={2} style={{ lineHeight: 1 }}>
                  <small>
                    <NavLink onClick={() => setFocused(false)} to={`/search?type=COMPANY&keyword=${k}`}>
                      View All
                    </NavLink>
                  </small>
                </div>
              </div>
              <div>
                {
                  suggestions.companySearches.map(s => (
                    <div className="text-left text-wrap py-1 px-2" key={s.company.id}>
                      <NavLink to={`/profile/${s.company.profileName}`} onClick={() => setSearchAnchor(null)}>- {s.company.name}</NavLink>
                    </div>
                  ))
                }
              </div>
            </div>
          }

          {
            Object.keys(suggestions).includes('productSearches') &&
            suggestions.productSearchStats && suggestions.productSearchStats.all && suggestions.productSearchStats.all.count !== 0 &&
            <div className='m-2'>
              <div display="flex">
                <div>
                  <h6><b>Products found: {suggestions.productSearchStats && suggestions.productSearchStats.all ? suggestions.productSearchStats.all.count : 0}</b></h6>
                </div>
                <div ml={2} style={{ lineHeight: 1 }}>
                  <small>
                    <NavLink onClick={() => setFocused(false)} className="" to={`/search?type=PRODUCT&keyword=${k}`}>
                      View All
                    </NavLink>
                  </small>
                </div>
              </div>
              <div>
                {
                  suggestions.productSearches.map(s => (
                    <div className="text-left text-wrap py-1 px-2" key={s.id}>
                      <NavLink to={`/products/${s.company.profileName}/${s.product.sku}`} onClick={() => setSearchAnchor(null)}>- {s.product.name}</NavLink>
                    </div>
                  ))
                }
              </div>
            </div>
          }

          {
            Object.keys(suggestions).includes('rfqSearches') &&
            suggestions.rfqSearchStats && suggestions.rfqSearchStats.all && suggestions.rfqSearchStats.all.count !== 0 &&
            <div className='m-2'>
              <div display="flex">
                <div>
                  <h6><b>RFQs found: {suggestions.rfqSearchStats && suggestions.rfqSearchStats.all ? suggestions.rfqSearchStats.all.count : 0}</b></h6>
                </div>
                <div ml={2} style={{ lineHeight: 1 }}>
                  <small>
                    <NavLink onClick={() => setFocused(false)} className="" to={`/search?type=RFQ&keyword=${k}`}>
                      View All
                    </NavLink>
                  </small>
                </div>
              </div>
              <div>
                {
                  suggestions.rfqSearches.map(s => (
                    <div className="text-left text-wrap py-1 px-2" key={s.id}>
                      <NavLink to={`/rfqs/${s.rfq.id}`} onClick={() => setSearchAnchor(null)}>- {s.rfq.title}</NavLink>
                    </div>
                  ))
                }
              </div>
            </div>
          }

          {
            suggestions.companyGroupSearches &&
            suggestions.companyGroupSearchStats && suggestions.companyGroupSearchStats.all && suggestions.companyGroupSearchStats.all.count !== 0 &&
            <div className='m-2'>
              <div display="flex">
                <div>
                  <h6><b>Company groups found: {suggestions.companyGroupSearchStats && suggestions.companyGroupSearchStats.all ? suggestions.companyGroupSearchStats.all.count : 0}</b></h6>
                </div>
                <div ml={2} style={{ lineHeight: 1 }}>
                  <small>
                    <NavLink onClick={() => setFocused(false)} className="" to={`/search?type=GROUP&keyword=${k}`}>
                      View All
                    </NavLink>
                  </small>
                </div>
              </div>
              <div>
                {
                  suggestions.companyGroupSearches.map(s => (
                    <div className="text-left text-wrap py-1 px-2" key={s.id}>
                      <NavLink to={`/groups/${s.companyGroup.profileName}`} onClick={() => setSearchAnchor(null)}>- {s.companyGroup.name}</NavLink>
                    </div>
                  ))
                }
              </div>
            </div>
          }
        </div>
      </div>
    </form>
  );
}

export default HomeSearchBoxNew;
