import React from 'react';

import AuthContext from '../../AuthContext';
import InstantChatManager from '../../components/chat-service/messages/InstantChatManager';
import mtzApis from '../../services';
import Header from '../ParentLayout2/Header';
import NonLoginHeader from '../ParentLayout2/NonLoginHeader';

const { networkService, commonUtil, companyService } = mtzApis;

function ParentLayout2(props) {
  const me = React.useContext(AuthContext);
  let [allowAccess, setAllowAccess] = React.useState(false);

  const checkLogin = async () => {
    if (window.location.pathname.startsWith("/me") ||
      window.location.pathname.startsWith("/timeline") ||
      window.location.pathname.startsWith("/mtz-admin")) {
      const tokens = commonUtil.getMTZLoginTokens();
      if (!tokens || tokens.length === 0) {
        this.state.allowAccess = false;
        return;
      }
    }

    setAllowAccess(true);
  }

  React.useEffect(() => {
    checkLogin();
  }, [window.location.href]);

  return (
    <>
      {
        allowAccess === true ?
          <div className="vh-100 w-100 d-flex flex-column">
            {!!me.userId && <InstantChatManager />}

            <div className='w-100 sticky-top'>
              {
                !!me.userId ?
                  <Header /> :
                  <NonLoginHeader />
              }
            </div>

            <div className='h-100 d-flex flex-grow-1 flex-column'>
              <props.component />
            </div>
          </div>
          :
          <div className="p-2">Login required. Please <a href="/login">Login</a> to access.</div>
      }
    </>
  );
}

export default ParentLayout2;
