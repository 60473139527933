import commonUtil from "../common";
import {
  NETWORK_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

const createEmailMarketingCampaign = emailMarketingCampaign => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-marketing-campaigns`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, emailMarketingCampaign, headers);
}

const getEmailMarketingCampaignById = emailMarketingCampaignId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-marketing-campaigns/${emailMarketingCampaignId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const getEmailMarketingCampaigns = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-marketing-campaigns?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers, true, false);
}

const getMarketingEmailDynamicFields = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-marketing-campaigns/dynamic-fields`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers, true, false);
}

const getEmailMarketingCampaignStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-marketing-campaigns/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers, true, false);
}

export {
  createEmailMarketingCampaign,
  getEmailMarketingCampaigns,
  getEmailMarketingCampaignStats,
  getMarketingEmailDynamicFields,
  getEmailMarketingCampaignById
}