import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React from 'react';
import { NavLink } from 'react-router-dom';
import HomeSearchBoxNew from "../../components/shared2/HomeSearchBoxNew";
import MainMenu from '../../layouts/UserDashboardLayout2/MainMenu';
import './Header.css';

import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import Home from '@material-ui/icons/Home';
import { connect } from 'react-redux';
import AuthContext from '../../AuthContext';
import NotificationButton from '../../components/metric-service/notifications2/NotificationButton';
import MyProfileButton from '../../components/user-service/users2/MyProfileButton';
import mtzApis from '../../services';

const { companyService, paymentService, chatService } = mtzApis;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  search: {
    position: 'relative',
    padding: "4px 4px 4px 8px",
    backgroundColor: "#EFF1F7",
    border: "1px solid #EDEDED",
    borderRadius: "100px",
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    height: "48px",
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: '100%',
    },
  },
  searchbar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    gap: "16px"
  },
  searchIcon: {
    height: '32px',
    width: "32px",
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: "#3772FF",
    borderRadius: "100px",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%'
  }
}));

export default function Header() {
  const me = React.useContext(AuthContext);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  let [company, setCompany] = React.useState(null);
  let [mobileSearchBoxOnFocus, setMobileSearchBoxOnFocus] = React.useState(false);
  const [navMenuAnchor, setNavMenuAnchor] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      if (!me) return;

      company = await companyService.getMyCompany();
      if (company) setCompany(company);
    })();
  }, []);


  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <NavLink to="/">
          <img src="/assets/images/Logos.png" height="50px" />
        </NavLink>
      </MenuItem>
      <hr />

      <MainMenu />
    </Menu>
  );

  return (
    <div>
      <div className='d-flex mtz-gap-20 bg-white border py-1 align-items-center justify-content-center'>
        <div className="w-100 d-xl-flex d-none mtz-gap-20 justify-content-center align-items-center">
          <div className='logo d-none d-xl-inline-block'>
            <NavLink to="/">
              <img src="/assets/images/Logos.png" height="48px" />
            </NavLink>
          </div>

          <div className='d-none d-xl-flex searchbox text-center justify-content-center'>
            {
              me.role !== 'ADMIN' ?
                <>
                  <HomeSearchBoxNew />
                  &nbsp;
                  <span className='d-md-inline-block d-none'><PlanButton /></span>
                </> :
                <div className='text-primary mtz-h5'>Admin Portal</div>
            }
          </div>

          <div className={'d-none d-xl-flex right-buttons justify-content-end'}>
            <div className='d-flex mtz-gap-4 align-items-bottom justify-content-center'>
              {
                me.role !== 'ADMIN' &&
                <>
                  <Button size="small" href='/' className='p-0'>
                    <div className="align-items-center d-flex flex-column">
                      <Home style={{ fontSize: '21px', color: '#333' }} />
                      <small className="text-nowrap">Home</small>
                    </div>
                  </Button>

                  <div className='py-1'><NotificationButton color="#555" /></div>

                  {/* <div className='py-1'><ReduxHeaderChatButton color="#555" /></div> */}

                  <Button size="small" to={'/chat'} onClick={e => setNavMenuAnchor(e.target)}>
                    <div className="align-items-center d-flex flex-column">
                      <MoreVertIcon style={{ fontSize: '24px', color: '#333' }} />
                      <small className="text-nowrap">Menu</small>
                    </div>
                  </Button>

                  <Popover anchorEl={navMenuAnchor} open={!!navMenuAnchor} onClose={() => setNavMenuAnchor(null)}>
                    <MainMenu />
                  </Popover>
                </>
              }

              <div className='py-1'><MyProfileButton /></div>
            </div>
          </div>
        </div>

        <div className='d-xl-none d-flex mtz-gap-4 justify-content-center align-items-center'>
          {
            !mobileSearchBoxOnFocus &&
            <>
              {
                me.role !== 'ADMIN' &&
                <div className={'d-xl-none d-flex'}>
                  <IconButton className='p-2'
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                  >
                    <MenuIcon />
                  </IconButton>
                </div>
              }

              <NavLink to="/">
                <div className='px-1' >
                  <img src="/assets/images/Logos_1.png" height="30px" alt="MyTradeZone Logo" />
                </div>
              </NavLink>
            </>
          }

          {
            me.role !== 'ADMIN' ?
              <div className='p-1 d-xl-none d-inline-block w-100'>
                <HomeSearchBoxNew onFocus={() => setMobileSearchBoxOnFocus(true)} onBlur={() => setMobileSearchBoxOnFocus(false)} />
              </div> :
              <div className='mtz-h5 text-primary flex-fill text-center'>Admin Portal</div>
          }

          {
            !mobileSearchBoxOnFocus &&
            <>
              {
                <IconButton className='p-2'><Home style={{ color: '#333' }} /></IconButton>
              }

              <MyProfileButton />
            </>
          }
        </div>
      </div >

      {/* {
        me.role !== 'ADMIN' &&
        <div className='shadow-sm text-primary justify-content-center align-items-center d-xl-flex d-none mtz-gap-8 p-1 border-primary border-top bg-white'>
          <PlanButton />

          <NavLink to="/me/products">
            <span className="text-primary">Products/Services</span>
          </NavLink>
          |
          <NavLink to="/me/connections">
            <span className="text-primary">Connections</span>
          </NavLink>
          |
          <NavLink to="/me/groups">
            <span className="text-primary">Groups</span>
          </NavLink>
          {company && (
            <>
              |
              <NavLink to={`/profile/${company.profileName}`}>
                <span className="text-primary">My Company</span>
              </NavLink>
            </>
          )}
        </div>
      } */}

      {renderMobileMenu}
    </div >
  );
}

export function PlanButton() {
  const me = React.useContext(AuthContext);
  let [mySub, setMySub] = React.useState();

  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    let subs = await paymentService.getMtzPlanSubscriptions(new URLSearchParams(``));
    if (subs && subs.length > 0) {
      subs[0].plan = await paymentService.getMtzPlanById(subs[0].mtzPlanId);
      setMySub(subs[0]);
    }
  };

  if (!me)
    return <></>;

  return (
    <span className=''>
      {
        !mySub || !mySub.plan ?
          <NavLink to={`/pricing`}
            className='text-primary'><i><b><small><u>Try premium</u></small></b></i>
          </NavLink> :
          (
            <NavLink className='text-dark' to={`/me/billing/subscriptions/`}>
              <i><b><small className='d-flex flex-column'>
                My Plan:
                <span className='text-nowrap'>
                  {!mySub.cancelled ? <span className='text-info'>{mySub.plan.name.replaceAll(' ', '')}</span> : <span className='text-danger'>canceled</span>}
                </span>
              </small></b></i>
            </NavLink>
          )
      }
    </span>
  );
}


function HeaderChatButton({ newMessageEvent, newMessageViewerEvent }) {
  const me = React.useContext(AuthContext);
  let [unreadMsgMap, setUnreadMsgMap] = React.useState({});

  React.useMemo(async () => {
    if (!me.userId) return;

    let msg = newMessageEvent.resource;
    if (!msg) return;

    if (!unreadMsgMap[msg.roomId])
      unreadMsgMap[msg.roomId] = [];

    if (newMessageEvent.action === 'CREATED')
      if (!msg.viewers.includes(me.userId))
        unreadMsgMap[msg.roomId].push(msg);


    if (newMessageEvent.action === 'UPDATED')
      if (!msg.viewers.includes(me.userId))
        if (!unreadMsgMap[msg.roomId].find(m => m.id === msg.id))
          unreadMsgMap[msg.roomId].push(msg);

    if (newMessageEvent.action === 'DELETED')
      if (unreadMsgMap[msg.roomId])
        delete unreadMsgMap[msg.roomId];

    setUnreadMsgMap({ ...unreadMsgMap });
  }, [newMessageEvent]);

  React.useMemo(() => {
    if (!me.userId) return;

    if (newMessageViewerEvent && newMessageViewerEvent.resource && newMessageViewerEvent.action === 'CREATED') {
      let msg = newMessageViewerEvent.resource;
      if (msg.ownerId !== me.userId)
        if (unreadMsgMap[msg.roomId]) {
          unreadMsgMap[msg.roomId] = unreadMsgMap[msg.roomId].filter(m => m.id !== msg.id);
          setUnreadMsgMap(unreadMsgMap);
        }
    }
  }, [newMessageViewerEvent]);

  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    if (!me.userId) return;

    // load unreads
    let unreads = await chatService.getMessages(new URLSearchParams(`notSeenBy=${me.userId}`));
    if (unreads) {
      unreadMsgMap = {};
      unreads.forEach(msg => {
        if (!unreadMsgMap[msg.roomId])
          unreadMsgMap[msg.roomId] = [];
        unreadMsgMap[msg.roomId].push(msg);
      });
      setUnreadMsgMap(unreadMsgMap);
    }
  };

  return (
    <React.Fragment>
      {
        me && Object.keys(me).length !== 0 &&
        <span>
          <Tooltip title="Click To See Notifications">
            <Badge badgeContent={unreadMsgMap && Object.values(unreadMsgMap).reduce((r, i) => r + i.length, 0)} color="secondary" overlap="circular">
              <Button component={NavLink} to='/chat' className="p-0" size="small">
                <div className={`align-items-center d-flex flex-column mtz-gap-4`} >
                  <img src="/assets/images/chat_icon.svg" />
                  <small className="text-nowrap">Chat</small>
                </div>
              </Button>
            </Badge>
          </Tooltip>
        </span >
      }
    </React.Fragment>
  );
}

const stateToProp = state => ({
  newMessageEvent: state.message_topic.new_uievent,
  newMessageViewerEvent: state.messageViewer_topic.new_uievent
});
const ReduxHeaderChatButton = connect(stateToProp, null)(HeaderChatButton);