import commonUtil from "../common";
import {
  NETWORK_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const createTrackingCodeLog = trackingCodeLog => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/tracking-code-logs`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, trackingCodeLog, headers);
}

export const getTrackingCodeLogs = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/tracking-code-logs?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getTrackingCodeStatStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/tracking-code-logs/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}