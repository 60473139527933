import commonUtil from "../common";
import {
  USER_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = USER_SERVER_URL;

export const createMtzFileSecret = mtzFile => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-file-secrets`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, mtzFile, headers);
}

export const deleteMtzFileSecretById = mtzFileId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-file-secrets/${mtzFileId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getMtzFileSecretById = mtzFileId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-file-secrets/${mtzFileId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getMtzFileSecrets = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-file-secrets?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getMtzFileSecretStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-file-secrets/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}