import React from "react";


import AuthContext from "../../../../AuthContext";
import mtzApis from "../../../../services";
import "./style.css";

const { companyService, networkService } = mtzApis;

export default function FollowButton({ companyId, profileName, style = {} }) {
  let [company, setCompany] = React.useState(null);
  let [follow, setFollow] = React.useState(null);

  const me = React.useContext(AuthContext);

  React.useEffect(() => {
    (async () => {
      // load company
      let company;

      if (companyId) {
        const companies = await companyService.getCompanies(
          new URLSearchParams(`companyIds=${companyId}`)
        );
        if (companies && companies.length > 0) {
          company = companies[0];
          setCompany(company);
        }
      }
      if (profileName) {
        const companies = await companyService.getCompanies(
          new URLSearchParams(`profileName=${profileName}`)
        );
        if (companies && companies.length > 0) {
          company = companies[0];
          setCompany(company);
        }
      }

      if (company) {
        const follows = await networkService.getFollows(
          new URLSearchParams(`companyIds=${company.id}&followerIds=${me.userId}`)
        );
        if (follows && follows.length > 0) {
          const follow = follows[0];
          setFollow(follow);
        } else {
          setFollow(null);
        }
      }
    })();
  }, [companyId, profileName]);

  const followHandler = async () => {
    if (!me || Object.keys(me).length === 0 || !me.userId) {
      const ok = await window.createMtzConfirm("Please login to follow");
      if (!ok) return;
      else {
        window.location.href = "/login?redirectUrl=" + window.location.pathname;
        return;
      }
    }

    let follow = { companyId: company.id, followerId: me.userId };
    mtzApis.startSpinningIcon();
    follow = await networkService.createFollow(follow);
    mtzApis.stopSpinningIcon();

    if (follow) setFollow(follow);
  };

  const unfollowHandler = async () => {
    mtzApis.startSpinningIcon();
    let unfollowRes = await networkService.deleteFollowById(follow.id);
    mtzApis.stopSpinningIcon();

    if (unfollowRes) setFollow(null);
  };

  return (
    <React.Fragment>
      <div className="d-inline-block">
        {!follow ? (
          <button
            className="btn btn-primary btn-sm"
            style={style}
            onClick={followHandler}
          >
            Follow
          </button>
        ) : (
          <button
            className="btn btn-outline-secondary btn-sm"
            style={style}
            onClick={unfollowHandler}
          >
            Following
          </button>
        )}
      </div>
    </React.Fragment>
  );
}
