import commonUtil from "../common";
import {
  NETWORK_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const getSyncedProducts = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/synced-products?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getSyncedProductStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/synced-products/stats?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
};