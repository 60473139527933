import commonUtil from "../common";
import { NETWORK_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const createCGAdBannerAuction = (cgAdBannerAuction) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-ad-banner-auctions`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, cgAdBannerAuction, headers);
};

export const deleteCGAdBannerAuctionById = (cgAdBannerAuctionId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-ad-banner-auctions/${cgAdBannerAuctionId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCGAdBannerAuctionById = (cgAdBannerAuctionId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-ad-banner-auctions/${cgAdBannerAuctionId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCGAdBannerAuctions = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-ad-banner-auctions?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCGAdBannerAuctionStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-ad-banner-auctions/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const updateCGAdBannerAuctionById = (cgAdBannerAuctionId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-ad-banner-auctions/${cgAdBannerAuctionId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, params, headers);
};

export const getCGAdBannerAuctionByGroupId = (companyGroupId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-ad-banner-auctions/group/${companyGroupId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};
