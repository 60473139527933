import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getAdCampaigns = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getAdCampaignStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getAdCampaignById = (adCampaignId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns/${adCampaignId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const createAdCampaign = (adCampaign) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, adCampaign, headers);
};

export const deleteAdCampaignById = (adCampaignId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns/${adCampaignId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const updateAdCampaignById = (adCampaignId, adCampaign) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns/${adCampaignId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, adCampaign, headers);
};

export const createLocation = (adCampaignId, location) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns/${adCampaignId}/locations`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, location, headers);
};

export const deleteLocationById = (adCampaignId, locationId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns/${adCampaignId}/locations/${locationId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};
