import commonUtil from "../common";
import { NETWORK_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const createCompanyGroupPostSearchReport = (
  companyGroupPostSearchId,
  companyGroupPostSearchReport
) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-searches/${companyGroupPostSearchId}/reports`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, companyGroupPostSearchReport, headers);
};
