import commonUtil from "../common";
import { NETWORK_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const createCompanyGroupSearchReport = (companyGroupSearchId, companyGroupSearchReport) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-searches/${companyGroupSearchId}/reports`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, companyGroupSearchReport, headers);
};
