import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import React from "react";

import { NavLink } from "react-router-dom";
import AuthContext from "../../../AuthContext";
import mtzApis from "../../../services";

const { companyService, userService } = mtzApis;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function CompanyWatchlistAddButton({ companyId, profileName, style }) {
  let [started, setStarted] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  let [companyWatchlists, setCompanyWatchlists] = React.useState(null);
  const [selectedCompanyWatchlistId, setSelectedCompanyWatchlistId] = React.useState(null);
  const me = React.useContext(AuthContext);
  const [company, setCompany] = React.useState(null);

  React.useEffect(() => {
  }, [companyId, profileName]);

  const init = async () => {
    // get company
    let company;
    if (companyId) {
      const companies = await companyService.getCompanies(
        new URLSearchParams(`companyIds=${companyId}`)
      );
      company = companies.length > 0 ? companies[0] : null;
    }
    if (profileName) {
      const companies = await companyService.getCompanies(
        new URLSearchParams(`profileName=${profileName}`)
      );
      company = companies.length > 0 ? companies[0] : null;
    }
    if (!!company) setCompany(company);

    // load compayGroups
    if (me) {
      companyWatchlists = await companyService.getCompanyWatchlists(new URLSearchParams(""));
      if (companyWatchlists) setCompanyWatchlists(companyWatchlists);
    }
  };

  const addToCompanyWatchlist = async () => {
    mtzApis.startSpinningIcon();
    let companyWatchlist = await companyService.getCompanyWatchlistById(selectedCompanyWatchlistId);
    if (companyWatchlist) {
      let companyIds = companyWatchlist.companyIds || [];
      let index = companyIds.indexOf(company.id);

      if (index >= 0) {
        alert("The list '" + companyWatchlist.name + "' has this company already");
        mtzApis.stopSpinningIcon();
        return;
      }

      companyIds.push(company.id);
      companyWatchlist = await companyService.updateCompanyWatchlistById(companyWatchlist.id, {
        companyIds,
      });
      mtzApis.stopSpinningIcon();

      if (companyWatchlist) alert("The company is added successfully");
    }
  };

  const handleOpen = async () => {
    if (!started) {
      await init();
      setStarted(true);
    }

    if (!me || Object.keys(me).length === 0 || !me.userId) {
      const ok = await window.createMtzConfirm("Please login to add watchlist");
      if (!ok) return;
      else {
        window.location.href = "/login?redirectUrl=" + window.location.pathname;
        return;
      }
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCompanyWatchlistId(null);
  };

  return (
    <Box className="d-inline-block">
      <button
        className="btn btn-outline-primary btn-sm"
        style={style}
        onClick={handleOpen}
      >
        <span className="fa fa-list-check"></span>
        &nbsp;
        Add to Watchlist
      </button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        component={Paper}
        TransitionComponent={Transition}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>My company watchlists:</DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item>
              <Select
                style={{ minWidth: 300 }}
                value={selectedCompanyWatchlistId || ""}
                onChange={(event) => setSelectedCompanyWatchlistId(event.target.value)}
              >
                <MenuItem value="" disabled selected>
                  <em>-- Select --</em>
                </MenuItem>
                {companyWatchlists &&
                  companyWatchlists.map((companyWatchlist) => (
                    <MenuItem key={companyWatchlist.id} value={companyWatchlist.id}>
                      {companyWatchlist.name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                style={{ width: "100px" }}
                onClick={() => {
                  addToCompanyWatchlist();
                  handleClose();
                }}
                disabled={selectedCompanyWatchlistId ? false : true}
              >
                Add
              </Button>
            </Grid>
          </Grid>
          <hr />
          <Grid container spacing={2}>
            <Typography variant="caption">
              Click{" "}
              <NavLink target="_blank" to="/me/company-watchlists">
                <b> HERE </b>{" "}
              </NavLink>{" "}
              to add new company watchlist.
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" color="default" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
