import commonUtil from "../common";
import { NETWORK_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const createCGSponsorPlanPrice = (companyGroupSponsorPlanPrice) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-plan-prices`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, companyGroupSponsorPlanPrice, headers);
};

export const deleteCGSponsorPlanPriceById = (companyGroupSponsorPlanPriceId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-plan-prices/${companyGroupSponsorPlanPriceId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const updateCGSponsorPlanPriceById = (companyGroupSponsorPlanPriceId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-plan-prices/${companyGroupSponsorPlanPriceId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

export const getCGSponsorPlanPriceById = (companyGroupSponsorPlanPriceId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-plan-prices/${companyGroupSponsorPlanPriceId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGSponsorPlanPrices = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-plan-prices?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGSponsorPlanPriceStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-plan-prices/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};