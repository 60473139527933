import React from 'react';

import BannerImageForm from '../../../shared2/BannerImageForm';

import AuthContext from "../../../../AuthContext";
import mtzApis from "../../../../services";
const { companyService } = mtzApis;

function CompanyBannerManager({ onUpload, companyId, profileName, width }) {
	let [company, setCompany] = React.useState(null);
	let [w, setW] = React.useState(width);
	const me = React.useContext(AuthContext);
	const bannerRef = React.useRef();

	React.useEffect(() => {
		(async () => {
			if (companyId) {
				const companies = await companyService.getCompanies(`companyId=${companyId}`);
				if (companies) {
					company = companies[0];
					setCompany(company);
				}
			}

			if (profileName) {
				const companies = await companyService.getCompanies(`profileName=${profileName}`);
				if (companies) {
					company = companies[0];
					setCompany(company);
				}
			}

			if (bannerRef && bannerRef.current) {
				if (!width) {
					setW(bannerRef.current.offsetWidth);
				} else
					setW(width);
			}
		})();


	}, [companyId, profileName, width]);

	const uploadBanner = async fileInfo => {
		mtzApis.startSpinningIcon();
		const data = new FormData()
		data.append("bannerFile", new File([fileInfo.blob], 'bannerFile.' + fileInfo.ext))
		company = await companyService.updateCompanyLogoBannerById(
			company.id,
			data
		);
		mtzApis.stopSpinningIcon();
		if (company) {
			setCompany(company);
			if (onUpload)
				onUpload(company.bannerUrl);
		}
	}

	return (
		<React.Fragment>
			{
				company &&
				<div id="mtz-company-banner" className='w-100 shadow-sm' ref={bannerRef}>
					{
						company && w &&
						<BannerImageForm height={w / 4} width={w}
							editable={company && me && me.userId === company.ownerId}
							imageUrl={companyService.getBackendHost() + (company.bannerUrl || '')}
							onUpload={uploadBanner}
						/>
					}
				</div>
			}
		</React.Fragment>
	);
}

export default props => (
	<AuthContext.Consumer>
		{
			val => <CompanyBannerManager {...props} me={val} />
		}
	</AuthContext.Consumer>
);