import commonUtil from "../common";
import { NETWORK_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const createCGEventResource = (cgEventResource) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-resources`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, cgEventResource, headers);
};

export const deleteCGEventResourceById = (cgEventResourceId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-resources/${cgEventResourceId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCGEventResourceById = (cgEventResourceId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-resources/${cgEventResourceId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCGEventResources = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-resources?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const getCGEventResourceStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-resources/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const updateCGEventResourceById = (cgEventResourceId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-resources/${cgEventResourceId}`;
  let headers = {};
  return commonUtil.httpclient(method, url, params, headers);
};

export const updateCGEventResourceFileById = (cgEventResourceId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-resources/${cgEventResourceId}/_uploadFile`;
  let headers = {};
  return commonUtil.httpclient(method, url, params, headers);
};
