export const ACCOUNT_CIRCLE_BLACK_ICON = "/assets/icons2/account_circle_black_icon.svg";
export const ACCOUNT_CIRCLE_GRAY_ICON = "/assets/icons2/account_circle_gray_icon.svg";
export const ACCOUNT_CIRCLE_WHITE_ICON = "/assets/icons2/account_circle_white_icon.svg";
export const ADS_CLICK_BLACK_ICON = "/assets/icons2/ads_click_black_icon.svg";
export const ATR_BLACK_ICON = "/assets/icons2/atr_black_icon.svg";
export const BARCODE_SCANNER_BLACK_ICON = "/assets/icons2/barcode_scanner_black_icon.svg";
export const BROKEN_IMAGE_BLACK_ICON = "/assets/icons2/broken_image_black_icon.svg";
export const CATEGORY_BLACK_ICON = "/assets/icons2/category_black_icon.svg";
export const CATEGORY_WHITE_ICON = "/assets/icons2/category_white_icon.svg";
export const COMPANY_BLACK_ICON = "/assets/icons2/company_black_icon.svg";
export const COMPANY_WHITE_ICON = "/assets/icons2/company_white_icon.svg";
export const CONTACT_PAGE_BLACK_ICON = "/assets/icons2/contact_page_black_icon.svg";
export const CONTACT_PAGE_WHITE_ICON = "/assets/icons2/contact_page_white_icon.svg";
export const CONTACTS_BLACK_ICON = "/assets/icons2/contacts_black_icon.svg";
export const DASHBOARD_ICON = "/assets/icons2/dashboard_icon.svg";
export const EXPLORE_BLACK_ICON = "/assets/icons2/explore_black_icon.svg";
export const EXPLORE_WHITE_ICON = "/assets/icons2/explore_white_icon.svg";
export const GROUPS2_BLACK_ICON = "/assets/icons2/groups_2_black_icon.svg";
export const GROUPS2_WHITE_ICON = "/assets/icons2/groups_2_white_icon.svg";
export const HANDSHAKE_BLACK_ICON = "/assets/icons2/handshake_black_icon.svg";
export const HANDSHAKE_WHITE_ICON = "/assets/icons2/handshake_white_icon.svg";
export const HUB_BLACK_ICON = "/assets/icons2/hub_black_icon.svg";
export const HUB_WHITE_ICON = "/assets/icons2/hub_white_icon.svg";
export const LIST_BLACK_ICON = "/assets/icons2/list_black_icon.svg";
export const LIST_BLUE_ICON = "/assets/icons2/list_blue_icon.svg";
export const NOTIFICATION_ICON = "/assets/icons2/notification_icon.svg";
export const PERSON_SEARCH_BLACK_ICON = "/assets/icons2/person_search_black_icon.svg";
export const PERSON_SEARCH_WHITE_ICON = "/assets/icons2/person_search_white_icon.svg";
export const PERSON_SEARCH_BLUE_ICON = "/assets/icons2/person_search_blue_icon.svg";
export const PUBLISH_BLACK_ICON = "/assets/icons2/publish_black_icon.svg";
export const RFQ_WHITE_ICON = "/assets/icons2/rfq_white_icon.svg";
export const RFQ_BLACK_ICON = "/assets/icons2/rfq_black_icon.svg";
export const SETTINGS_BLACK_ICON = "/assets/icons2/settings_black_icon.svg";
export const SPACE_DASHBOARD_WHITE_ICON = "/assets/icons2/space_dashboard_white_icon.svg";
export const SPACE_DASHBOARD_BLACK_ICON = "/assets/icons2/space_dashboard_black_icon.svg";
export const SUPPORT_AGENT_BLACK_ICON = "/assets/icons2/support_agent_black_icon.svg";
export const SUPPORT_AGENT_WHITE_ICON = "/assets/icons2/support_agent_white_icon.svg";
export const VISIBILITY_BLACK_ICON = "/assets/icons2/visibility_black_icon.svg";
export const TEAM_DASHBOARD_BLUE_ICON = "/assets/icons2/team_dashboard_blue_icon.svg";
export const TEAM_DASHBOARD_BLACK_ICON = "/assets/icons2/team_dashboard_black_icon.svg";
export const SAVE_BLUE_ICON = "/assets/icons2/save_blue_icon.svg";
export const SAVE_WHITE_ICON = "/assets/icons2/save_white_icon.svg";
export const VERIFIED_BLUE_ICON = "/assets/icons2/verified_blue_icon.svg";
export const SAVE_BLACK_ICON = "/assets/icons2/save_black_icon.svg";
export const SAVE_FULL_BLACK_ICON = "/assets/icons2/save_full_black_icon.svg";
export const LIGHTBULB_ICON = "/assets/icons2/lightbulb.svg";
export const ARROW_LEFT_BLUE_ICON = "/assets/icons2/arrow_left_blue_icon.svg";
export const ADD_BLUE_ICON = "/assets/icons2/add_blue_icon.svg";
export const UPLOAD_BLACK_ICON = "/assets/icons2/upload_black_icon.svg";
export const GEAR_SEARCH_BLACK_ICON = "/assets/icons2/gear_search_black_icon.svg";
export const ADD_NOTE_BLUE_ICON = "/assets/icons2/add_note_blue_icon.svg";
export const FLAG_BLUE_ICON = "/assets/icons2/flag_blue_icon.svg";
export const CONNECT_BLACK_ICON = "/assets/icons2/connect_black_icon.svg";
export const TOOL_BLACK_ICON = "/assets/icons2/tool_black_icon.svg";
export const MARKET_BLACK_ICON = "/assets/icons2/market_black_icon.svg";
export const SOURCE_FILE_BLACK_ICON = "/assets/icons2/source_file_black_icon.svg";
export const GRID_BLACK_ICON = "/assets/icons2/grid_black_icon.svg";
