import React from 'react';

import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from '@material-ui/core/ListItemText'; import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import { NavLink } from 'react-router-dom';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { connect } from 'react-redux';

import mtzApis from "../../../../services";
import { ACCOUNT_CIRCLE_GRAY_ICON } from '../../../../utils/constants/icons';

const { userService, commonUtil } = mtzApis;

function MyProfileButton({ reduxUsers, vertical, noCaption }) {
  let [loggedIns, setLoggedIns] = React.useState(null);
  let [profileAnchor, setProfileAnchor] = React.useState(null);

  const loadUserInfo = async () => {
    const tokens = commonUtil.getMTZLoginTokens();
    if (tokens && tokens.length > 0) {
      let userIds = tokens.map(entry => Object.keys(entry)[0]);
      loggedIns = await userService.getUsers(`userIds=${userIds}`);
      let idUserMap = {};
      loggedIns.forEach(item => idUserMap[item.id] = item);
      loggedIns = userIds.map(id => idUserMap[id]).filter(item => item);
    } else
      loggedIns = [];

    setLoggedIns(loggedIns);
  }

  React.useEffect(() => {
    if (!loggedIns)
      (async () => {
        loadUserInfo();
      })();
    else {
      let map = {};
      reduxUsers.forEach(u => map[u.id] = u);
      loggedIns = loggedIns.map(login => map[login.id] ? map[login.id] : login);
      setLoggedIns(loggedIns);
    }
  }, [reduxUsers]);

  const logoutAll = async () => {
    if (!await window.createMtzConfirm("Are you sure you want to logout ALL of your account(s) on MyTradeZone?"))
      return;
    commonUtil.logoutAll();
    window.location.href = "/login";
  }

  const switchLogin = userId => {
    commonUtil.switchLogin(userId);
    window.location.reload();
  }

  const removeLogin = async userId => {
    const ok = await window.createMtzConfirm("Are you sure?");
    if (!ok) return;
    commonUtil.removeLogin(userId);

    if (commonUtil.getMTZLoginTokens() && commonUtil.getMTZLoginTokens().length > 0)
      window.location.reload();
    else
      window.location.href = "/login";
  }

  return (
    <span>
      {
        loggedIns && loggedIns.length === 0 &&
        <Box display="flex" ml={1} mr={1}>
          <Box>
            <Button className="text-nowrap mb-1 mt-2 font-weight-bold mx-2" onClick={() => window.location.href = "/login"}>
              SIGN IN
            </Button>
          </Box>
          <Box>
            <Button className="text-nowrap mx-2 my-1 border-3" color="secondary" variant="outlined" style={{ textTransform: "none", borderRadius: "25px" }}
              onClick={() => window.location.href = "/register"}>
              Join<span className="d-none d-sm-inline-block">&nbsp;now</span><span className="d-none d-md-inline-block">&nbsp;for free</span>
            </Button>
          </Box>
        </Box>
      }

      {
        loggedIns && loggedIns.length > 0 &&
        <Button
          className={noCaption !== true ? "p-0 m-0" : ''}
          onClick={e => {
            setProfileAnchor(e.target);
          }}
        >
          <span className={(vertical === false ? 'd-flex' : 'flex-column d-flex align-items-center')}>
            <div className={"d-flex align-items-center"}>
              {
                loggedIns[0].logo ?
                  <Avatar src={loggedIns[0].logo.startsWith("http") ? loggedIns[0].logo : (userService.getBackendHost() + loggedIns[0].logo)}>
                  </Avatar> :
                  <img src={ACCOUNT_CIRCLE_GRAY_ICON} />
              }

              <ArrowDropDownIcon className="text-dark" />
            </div>

            {!loggedIns[0].logo && noCaption !== true && <span className="text-nowrap" style={{ fontSize: '.875em' }}>Profile</span>}
          </span>
        </Button>
      }

      <Popover className='z-index-10000 mtz-rounded-16' open={!!profileAnchor} anchorEl={profileAnchor} onClose={() => setProfileAnchor(null)}>
        <MenuList className='bg-white shadow p-2'>
          {
            loggedIns && loggedIns.map(
              (user, idx) => (
                <MenuItem key={user && user.id} className={idx === 0 ? "bg-primary mtz-rounded-16 text-white" : "text-secondary"}
                  onClick={() => { switchLogin(user.id); }}
                >
                  <Avatar style={{ width: "33px", height: "33px", border: "1px solid #eee", background: "#eee" }} key={user.logo} src={user && user.logo ? (userService.getBackendHost() + user.logo) : "/assets/images/empty.png"} />
                  &nbsp;
                  <small className="">
                    <b>{user.firstname + " " + user.lastname}<br /></b>
                    <i>{user.email}</i>
                  </small>

                  <ListItemSecondaryAction>
                    <Tooltip title="Logout">
                      <IconButton size="small" onClick={() => removeLogin(user.id)}>
                        <PowerSettingsNewIcon color="secondary" />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </MenuItem>
              )
            )
          }
          <Divider />
          <ListItem className="p-1 text-center" button onClick={() => window.location.href = "/login"}>
            <ListItemText className="p-1 border bg-light mtz-rounded-16" secondary={<small><PersonAddIcon /> &nbsp; Add another account</small>} />
          </ListItem>
          <ListItem className="p-1 text-center" button to="/reset" component={NavLink}>
            <ListItemText className="p-1 bg-light mtz-rounded-16 border border-danger" secondary={<small className="text-danger">Reset Password</small>} />
          </ListItem>
          <Divider />
          <ListItem button className="p-2" onClick={() => window.location.href = "/me/account"}>
            <ListItemText className='text-right text-dark' secondary="Account settings" />
          </ListItem>
          <Divider />
          {/* <ListItem button onClick={() => window.location.href = "/me/membership"}>
            <ListItemText secondary="Company membership/invitation" />
          </ListItem>
          <Divider /> */}
          <ListItem className="p-2" button onClick={logoutAll}>
            <span className='text-danger ml-auto'>
              Logout all account(s)
            </span>
          </ListItem>
        </MenuList>
      </Popover>
    </span>
  );
}

const mapStateToProps = state => ({
  reduxUsers: state.users
});

const mapDispatchToProps = dispatch => ({

});

const MyProfileButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyProfileButton);

export default MyProfileButtonContainer;