import commonUtil from "../common";
import { NETWORK_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const getCompanyGroupPostComments = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-comments?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCompanyGroupPostCommentStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-comments/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCompanyGroupPostCommentById = (companyGroupPostCommentId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-comments/${companyGroupPostCommentId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const createCompanyGroupPostComment = (comment) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-comments`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, comment, headers);
};

export const deleteCompanyGroupPostCommentById = (companyGroupPostCommentId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-comments/${companyGroupPostCommentId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const updateCompanyGroupPostCommentById = (companyGroupPostCommentId, comment) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-comments/${companyGroupPostCommentId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, comment, headers);
};
