import commonUtil from "../common";
import {
  NETWORK_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const generateStaticPagesSiteMap = params => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/sitemap/static`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, params, headers);
}

export const generateIndexSiteMap = params => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/sitemap/index`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, params, headers);
}

export const generateCompanyGroupSiteMap = params => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/sitemap/company-groups`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, params, headers);
}


export const generateCompanyGroupPostSiteMap = params => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/sitemap/company-group-posts`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, params, headers);
}


export const generateFeedDetailSiteMap = params => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/sitemap/feed-details`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, params, headers);
}

export const generateCGEventSiteMap = params => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/sitemap/cg-events`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, params, headers);
}