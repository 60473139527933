import alertService from "./alert";
import chatService from "./chat";
import companyService from "./company";
import metricService from "./metric";
import userService from "./user";
// import cronService from "./cron";
import commonUtil from "./common";
import networkService from "./network";
import paymentService from "./payment";

const startSpinningIcon = msg => {
  let spinner = `
    <div className="d-flex h-100 justify-content-center">
      <div className="align-self-center">
        <div className="spinner-grow m-0 p-0" role="status"></div>
        <div>${msg || 'Loading...'}</div>
      </div>
    </div>
  `;
  let spinnerEle = document.createElement("div");
  spinnerEle.id = "mtz-global-spinner";
  spinnerEle.style = "opacity:.75;background:gray;width:100vw;height:100vh;position:fixed;top:0;left:0;z-index:99999;";
  spinnerEle.classList.add("text-center");
  spinnerEle.innerHTML = spinner;
  document.body.appendChild(spinnerEle);
};

const stopSpinningIcon = () => {
  if (document.querySelector("#mtz-global-spinner"))
    document.querySelector("#mtz-global-spinner").remove();
};

const checkIntroComplete = () => {
  let step = localStorage.getItem("MTZ_INTRO_STEP");
  if (step)
    window.location.href = `/me/intro?step=${step}&leftover=true`;
}

export default {
  userService,
  companyService,
  metricService,
  chatService,
  alertService,
  networkService,
  paymentService,

  startSpinningIcon,
  stopSpinningIcon,
  checkIntroComplete,

  commonUtil
};