import React from 'react';

import { Redirect } from 'react-router-dom';
import AuthContext from '../../AuthContext';
import ParentLayout2 from '../ParentLayout2';

function ParentAuthLayout2(props) {
  const me = React.useContext(AuthContext);

  if (me && me.userId)
    return (
      <>
        <ParentLayout2 {...props} />
      </>
    );
  else
    return <Redirect to="/login" />;
}

export default ParentAuthLayout2;
