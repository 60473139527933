import commonUtil from "../common";
import { NETWORK_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const createCGSponsorInvite = (cgSponsorInvite) => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-invites`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, cgSponsorInvite, headers);
};

export const deleteCGSponsorInviteById = (cgSponsorInviteId) => {
    let method = "DELETE";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-invites/${cgSponsorInviteId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

export const getCGSponsorInviteById = (cgSponsorInviteId) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-invites/${cgSponsorInviteId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

export const getCGSponsorInvites = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-invites?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const getCGSponsorInviteStats = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-invites/stats?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const updateCGSponsorInviteById = (cgSponsorInviteId, params) => {
    let method = "PATCH";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-invites/${cgSponsorInviteId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, params, headers);
};
