import React from "react";
import './index.css';

class WithTwoColumnLayout extends React.Component {

    render() {
        return (
            <div className="d-flex mtz-gap-20 justify-content-center WithTwoColumnLayout">
                <div className="" style={{ width: '1316px' }}>
                    <div className="d-flex mtz-gap-16">
                        <div className="d-none d-lg-flex mtz-rounded-16" style={{ width: '319px' }}>
                            <div className="h-100 overflow-auto">
                                {this.props.left && <this.props.left />}
                            </div>
                        </div>

                        <div className="flex-fill mtz-rounded-16 bg-white p-2 border">
                            {this.props.center && <this.props.center />}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default WithTwoColumnLayout;