import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { directRoomEventPublish } from "../../../../redux/actions/directRoomEventActions.js";

import AuthContext from "../../../../AuthContext.js";
import mtzApis from "../../../../services";
import "./style.css";

const { chatService } = mtzApis;

function ChatButton2({ targetUserId, color, publishDirectRoomEvent, label }) {
  const me = React.useContext(AuthContext);
  let [directRoom, setDirectRoom] = React.useState(null);

  React.useEffect(() => {
    subscribeEvents();
  }, []);

  const subscribeEvents = async () => { };

  const clickLink = async () => {
    if (!me || Object.keys(me).length === 0 || !me.userId) {
      const ok = await window.createMtzConfirm("Please login to chat");
      if (!ok) return;
      else {
        window.location.href = "/login?redirectUrl=" + window.location.pathname;
        return;
      }
    }

    if (me.userId === targetUserId) {
      alert("You can't chat to yourself!");
      return;
    } else {
      mtzApis.startSpinningIcon();
      let myRooms = await chatService.getDirectRooms(new URLSearchParams(``));
      mtzApis.stopSpinningIcon();

      let exist = myRooms.find(
        (r) => r.fromMemberId === targetUserId || r.toMemberId === targetUserId
      );
      if (exist) {
        publishDirectRoomEvent({ resource: exist, action: "VIEWED" });
        return;
      }

      let creating = {
        toMemberId: targetUserId,
      };

      mtzApis.startSpinningIcon();
      let createdRoom = await chatService.createDirectRoom(creating);
      mtzApis.stopSpinningIcon();

      if (createdRoom) {
        setDirectRoom(createdRoom);
        publishDirectRoomEvent({ resource: createdRoom, action: "VIEWED" });
      }
    }
  };

  return (
    <button className="company-chat btn btn-primary mtz-btn w-100" onClick={clickLink}
    >
      <span className="message-button-font">Message</span>
    </button>
  );
}

const dispatchToProps = (dispatch) => ({
  publishDirectRoomEvent: (event) => dispatch(directRoomEventPublish(event)),
});

const ChatButtonWrapper = connect(null, dispatchToProps)(withRouter(ChatButton2));

export default ChatButtonWrapper;
