import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

const generateProductSiteMap = params => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/sitemap/products`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, params, headers);
}

const generateCompanySiteMap = params => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/sitemap/companies`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, params, headers);
}

export {
  generateProductSiteMap,
  generateCompanySiteMap,
}