import { USER_SERVER_URL } from "../config";


import * as authReqApi from './authReqApi';
import * as healthCheckApi from './healthCheckApi';
import * as healthCheckLogApi from './healthCheckLogApi';
import * as mtzFileApi from './mtzFileApi';
import * as mtzFileSecretApi from './mtzFileSecretApi';
import * as mtzSharedFileApi from './mtzSharedFileApi';
import * as userApi from './userApi';
import * as userBioApi from './userBioApi';
import * as userBioBannerApi from './userBioBannerApi';
import * as userSearchApi from './userSearchApi';

import {
  createEmails,
  getEmailCount,
  getEmails
} from "./emailApi";

import * as geoApi from "./geoApi";
import * as mtzExceptionApi from "./mtzExceptionApi";
import * as mtzFaqApis from "./mtzFaqApi";
import * as mtzWalletTransactionApi from "./mtzWalletTransactionApi";
import * as resourceSyncApi from './resourceSyncApi';
import * as sitemapApi from "./sitemapApi";
import * as socialAuthReqApi from './socialAuthReqApi';
import * as userExtraQuestionAnswerApi from './userExtraQuestionAnswerApi';
import * as userExtraQuestionApi from './userExtraQuestionApi';
import * as userExtraQuestionChoiceApi from './userExtraQuestionChoiceApi';

const getBackendHost = () => USER_SERVER_URL;

export default {
  ...authReqApi,
  ...userApi,
  ...userSearchApi,
  ...userBioApi,
  ...userBioBannerApi,

  createEmails,
  getEmails,
  getEmailCount,

  getBackendHost,

  ...mtzFaqApis,
  ...mtzWalletTransactionApi,
  ...resourceSyncApi,
  ...geoApi,
  ...socialAuthReqApi,
  ...mtzExceptionApi,
  ...sitemapApi,
  ...healthCheckApi,
  ...healthCheckLogApi,
  ...mtzFileApi,
  ...mtzFileSecretApi,
  ...mtzSharedFileApi,
  ...userExtraQuestionApi,
  ...userExtraQuestionAnswerApi,
  ...userExtraQuestionChoiceApi
};