import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getBannerAds = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/banner-ads?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getBannerAdStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/banner-ads/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getBannerAdById = (bannerAdId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/banner-ads/${bannerAdId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const createBannerAd = (bannerAd) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/banner-ads`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, bannerAd, headers);
};

export const deleteBannerAdById = (bannerAdId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/banner-ads/${bannerAdId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const updateBannerAdById = (bannerAdId, bannerAd) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/banner-ads/${bannerAdId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, bannerAd, headers);
};
