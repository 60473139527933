import commonUtil from "../common";
import { NETWORK_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const createCGMemberPlanSubscription = (companyGroupMemberPlanSubscription) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-plan-subscriptions`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, companyGroupMemberPlanSubscription, headers);
};

export const updateCGMemberPlanSubscriptionById = (companyGroupMemberPlanSubscriptionId, update) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-plan-subscriptions/${companyGroupMemberPlanSubscriptionId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, update, headers);
};

export const getCGMemberPlanSubscriptionById = (companyGroupMemberPlanSubscriptionId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-plan-subscriptions/${companyGroupMemberPlanSubscriptionId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGMemberPlanSubscriptions = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-plan-subscriptions?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGMemberPlanSubscriptionStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-plan-subscriptions/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};