import Badge from '@material-ui/core/Badge';
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import NotificationsIcon from '@material-ui/icons/Notifications';
import React from "react";
import { NavLink } from 'react-router-dom';

import { connect } from 'react-redux';

import AuthContext from "../../../../AuthContext";
import mtzApis from "../../../../services";

const { companyService, userService, chatService, alertService } = mtzApis;

function NotificationButton({ color, newNotiEvent, noTitle, vertical }) {
  let [notificationStats, setNotificationStats] = React.useState(null);
  let me = React.useContext(AuthContext) || {};
  const limit = 16;

  React.useMemo(() => {
    if (notificationStats && notificationStats.all) {
      ++notificationStats.all.count;
      setNotificationStats(notificationStats);
    }
  }, [newNotiEvent]);

  React.useEffect(() => {
    loadNotificationStats();
  }, [])

  const loadNotificationStats = async () => {
    let today = new Date();
    today.setDate(today.getDate() - 2);
    let startDateInMillis = today.getTime();
    notificationStats = await alertService.getNotificationStats(new URLSearchParams(`&startDateInMillis=${startDateInMillis}&hasRead=false&receiverIds=${me.userId}`));
    if (notificationStats && notificationStats.all) {
      setNotificationStats({ ...notificationStats });
    }
  }

  return (
    <React.Fragment>
      {
        me && Object.keys(me).length !== 0 &&
        <span>
          <Tooltip title="Click To See Notifications">
            <Badge key={notificationStats && notificationStats.all ? notificationStats.all.count : 0}
              badgeContent={
                notificationStats && notificationStats.all ?
                  (notificationStats.all.count <= 99 ? notificationStats.all.count : "99+") :
                  ''
              } color="secondary" overlap="circular">
              <Button component={NavLink} to='/me/notifications' className="p-0" size="small">
                <div className={`align-items-center d-flex ${vertical === false ? '' : ' flex-column'}`} >
                  {
                    notificationStats && notificationStats.all && notificationStats.all.count > 0 ?
                      <NotificationImportantIcon style={{ color: color, fontSize: '24px' }} /> :
                      <NotificationsIcon style={{ color: color, fontSize: '24px' }} />
                  }
                  {!noTitle && <small className="text-nowrap">Alerts</small>}
                </div>
              </Button>
            </Badge>
          </Tooltip>
        </span >
      }
    </React.Fragment>
  )
}

NotificationButton.defaultProps = {
  color: 'black',
}

const stateToProp = state => ({ newNotiEvent: state.notification_topic.new_uievent });

const NotificationButtonWrapper = connect(stateToProp)(NotificationButton);

export default NotificationButtonWrapper;
