import commonUtil from "../common";
import {
  USER_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = USER_SERVER_URL;

export const createMtzFaq = mtzFaq => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-faqs`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, mtzFaq, headers);
}

export const deleteMtzFaqById = mtzFaqId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-faqs/${mtzFaqId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getMtzFaqById = mtzFaqId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-faqs/${mtzFaqId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getMtzFaqs = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-faqs?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getMtzFaqStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-faqs/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const updateMtzFaqById = (mtzFaqId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-faqs/${mtzFaqId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}





