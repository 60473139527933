import commonUtil from "../common";
import {
  SERVER_API_ALL,
  USER_SERVER_URL,
} from "../config";
const SERVER_URL = USER_SERVER_URL;

export const createSocialAuthReq = user => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/social-auth-reqs`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, user, headers);
};
