import commonUtil from "../common";
import {
    NETWORK_SERVER_URL,
    SERVER_API_ALL,
} from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const createCouponUse = couponUse => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/coupon-use`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, couponUse, headers);
}

export const getCouponUse = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/coupon-use?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}