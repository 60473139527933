import React from "react";
import AuthContext from "../../AuthContext";
import FooterHori from '../ParentLayout2/FooterHori';
import './index.css';

class WithThreeColumnLayout extends React.Component {
    static contextType = AuthContext;


    render() {
        const me = this.context
        return (
            <div className="d-flex mtz-gap-20 justify-content-center WithThreeColumnLayout">
                <div className="d-none d-xl-flex flex-column mtz-gap-20 bot-left WithThreeColumnLayout-left-sidebar" style={{ width: '320px' }}>
                    <div className="h-100 overflow-auto">
                        {this.props.left && <this.props.left />}
                    </div>

                    <div className="flex-grow-1"></div>

                    <div className="">
                        <FooterHori />
                    </div>
                </div>

                <div className="WithThreeColumnLayout-content" style={{ width: '800px' }}>
                    {this.props.center && <this.props.center />}
                </div>

                <div className="d-none d-xl-flex vh-80 overflow-auto flex-column mtz-gap-20 WithThreeColumnLayout-right-sidebar" style={{ width: '420px' }}>
                    {this.props.right && <this.props.right />}
                </div>
            </div>
        );
    };
}

export default WithThreeColumnLayout;