// import { hot } from 'react-hot-loader/root';
import DateFnsUtils from "@date-io/date-fns";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import createMtzConfirm from "./components/shared2/ConfirmPopup";
import MTZSiteMap from "./MTZSiteMap";

import { callEventPublish } from "./redux/actions/callEventActions";
import { connectionEventPublish } from "./redux/actions/connectionEventActions";
import { directRoomEventPublish } from "./redux/actions/directRoomEventActions";
import { messageEventPublish } from "./redux/actions/messageEventActions";
import { messageFileEventPublish } from "./redux/actions/messageFileEventActions";
import { messageViewerEventPublish } from "./redux/actions/messageViewerEventActions";
import { mtzPlanSubscriptionEventPublish } from "./redux/actions/mtzPlanSubscriptionEventActions";
import { notificationEventPublish } from "./redux/actions/notificationEventActions";
import { presenceEventPublish } from "./redux/actions/presenceEventActions";
import { presenceSessionIdEventPublish } from "./redux/actions/presenceSessionIdEventActions";
import { roomInviteEventPublish } from "./redux/actions/roomInviteEventActions";
import { rtcRequestEventPublish } from "./redux/actions/rtcRequestEventActions";

import { connect } from "react-redux";
import mtzApis from "./services";
import MtzWebsocket from "./services/alert/MtzWebsocket";

import AuthContext from "./AuthContext";

import "./App.css";
import "./customBootstrap.css";
import "./customMui.css";
import "./mtz.css";
import { roomEventPublish } from "./redux/actions/roomEventActions";
// import { Helmet } from "react-helmet";

// G4Json
const g4Json = {
  "@context": "http://schema.org",
  "@type": "Organization",
  "url": "https://mytradezone.com",
  "name": "B2B Trade Networking | B2B Lead Generation | B2B Search | B2B Product Sourcing | B2B Company Contact List"
};

window.createMtzConfirm = createMtzConfirm;
const { metricService, userService, alertService, commonUtil, paymentService } = mtzApis;

const theme = createTheme({
  palette: {
    primary: {
      main: "#4081EC",
    },
    secondary: {
      main: "#B90301",
    },
  },
});

function App() {
  let [user, setUser] = React.useState();

  const init = async () => {
    mtzApis.startSpinningIcon();
    user = await getCurrUser();
    setUser({ userId: user.id, role: user.admin ? 'ADMIN' : 'REGULAR', email: user.email });
    mtzApis.stopSpinningIcon();
  };

  const getCurrUser = async () => {
    let user = await userService.getMyUser();
    if (!user)
      user = { sessionId: null };
    return user;
  };

  const setUserSessionId = uiid => {
    setTimeout(() => {
      mtzApis.startSpinningIcon();

      let count = 0;
      let interval = setInterval(() => {
        (async () => {
          let presences = await alertService.getPresences(new URLSearchParams(`uiid=${uiid + ""}`));
          if (presences[0]) {
            if (!!user) {
              user.sessionId = presences[0].sessionId || undefined;
              setUser({ ...user });
            }
            clearInterval(interval);
          }

          count++;
          if (count > 2)
            clearInterval(interval);
        })();

        mtzApis.stopSpinningIcon();
      }, [1000]);
    }, 1000)

  };

  React.useEffect(() => {
    init();
  }, []);

  return (
    <>
      {
        !user ?
          'Loading...' :
          <>
            <NotiListenerWrapper onWs={setUserSessionId} />
            <MtzPlanSubscriptionWrapper />
            <AuthContext.Provider value={user}>
              <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className="App">
                    <MTZSiteMap />
                  </div>
                </MuiPickersUtilsProvider>
              </MuiThemeProvider>
            </AuthContext.Provider>
          </>
      }
    </>
  );
}

function NotiListener({
  publishRoomInviteEvent,
  publishDirectRoomEvent,
  publishMessageEvent,
  publishMessageFileEvent,
  publishRoomEvent,
  publishCallEvent,
  publishCallInviteEvent,
  publishRtcRequestEvent,
  publishMessageViewerEvent,
  publishPresenceEvent,
  publishPresenceSessionIdEvent,
  publishConnectionEvent,
  publishNotificationEvent,
  publishMtzPlanSubscriptionEvent,
  onWs
}) {
  React.useEffect(() => {
    mtzApis.startSpinningIcon();
    let ws = new MtzWebsocket(onMessageReceived);
    mtzApis.stopSpinningIcon();

    if (ws)
      onWs(ws.getSessionId());
  }, []);

  const onMessageReceived = async (uiEvent) => {
    switch (uiEvent.resourceType) {
      case "MESSAGE":
        publishMessageEvent(uiEvent);
        break;
      case "MESSAGEFILE":
        publishMessageFileEvent(uiEvent);
        break;
      case "MESSAGEVIEWER":
        publishMessageViewerEvent(uiEvent);
        break;
      case "ROOMINVITE":
        publishRoomInviteEvent(uiEvent);
        break;
      case "DIRECTROOM":
        publishDirectRoomEvent(uiEvent);
        break;
      case "ROOM":
        publishRoomEvent(uiEvent);
        break;
      case "CALL":
        publishCallEvent(uiEvent);
        break;
      case "CALLINVITE":
        publishCallInviteEvent(uiEvent);
        break;
      case "PRESENCE":
        publishPresenceEvent(uiEvent);
        break;
      case "PRESENCESESSIONID":
        publishPresenceSessionIdEvent(uiEvent);
        break;
      case "CONNECTION":
        publishConnectionEvent(uiEvent);
        break;
      case "NOTIFICATION":
        publishNotificationEvent(uiEvent);
        break;
      case "RTCREQUEST":
        publishRtcRequestEvent(uiEvent);
        break;
      case "MTZPLANSUBSCRIPTION":
        publishMtzPlanSubscriptionEvent(uiEvent);
        break;
    }
  };

  return <span></span>;
}

const mapDispatchToProps = (dispatch) => ({
  publishDirectRoomEvent: (event) => dispatch(directRoomEventPublish(event)),
  publishRoomInviteEvent: (event) => dispatch(roomInviteEventPublish(event)),
  publishRoomEvent: (event) => dispatch(roomEventPublish(event)),
  publishMessageEvent: (event) => dispatch(messageEventPublish(event)),
  publishMessageFileEvent: (event) => dispatch(messageFileEventPublish(event)),
  publishMessageViewerEvent: (event) => dispatch(messageViewerEventPublish(event)),
  publishCallEvent: (event) => dispatch(callEventPublish(event)),
  publishRtcRequestEvent: (event) => dispatch(rtcRequestEventPublish(event)),
  publishPresenceEvent: (event) => dispatch(presenceEventPublish(event)),
  publishPresenceSessionIdEvent: (event) => dispatch(presenceSessionIdEventPublish(event)),
  publishConnectionEvent: (event) => dispatch(connectionEventPublish(event)),
  publishNotificationEvent: (event) => dispatch(notificationEventPublish(event)),
  publishMtzPlanSubscriptionEvent: (event) => dispatch(mtzPlanSubscriptionEventPublish(event)),
});

const NotiListenerWrapper = connect(null, mapDispatchToProps)(NotiListener);

function MtzPlanSubscription({ publishMtzPlanSubscriptionEvent }) {
  React.useEffect(() => {
    (async () => {
      let mtzPlanSubscriptions = await paymentService.getMtzPlanSubscriptions(
        new URLSearchParams(``)
      );
      if (mtzPlanSubscriptions && mtzPlanSubscriptions.length > 0) {
        let mtzPlanSubscription = mtzPlanSubscriptions[0];
        let uiEvent = {
          action: "FETCHED",
          resource: mtzPlanSubscription,
        };
        publishMtzPlanSubscriptionEvent(uiEvent);
      }
    })();
  }, []);

  return <></>;
}

const mtzPlanMapDispatchToProps = (dispatch) => ({
  publishMtzPlanSubscriptionEvent: (event) => dispatch(mtzPlanSubscriptionEventPublish(event)),
});

const MtzPlanSubscriptionWrapper = connect(null, mtzPlanMapDispatchToProps)(MtzPlanSubscription);

export default App;
