import commonUtil from "../common";
import {
  NETWORK_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const getFeeds = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feeds?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getFeedStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feeds/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getFeedById = (feedId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feeds/${feedId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const createFeed = (feed) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feeds`;
  let headers = { };
  return commonUtil.httpclient(method, url, feed, headers);
};

export const deleteFeedById = (feedId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feeds/${feedId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const deleteFeeds = (queries) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feeds?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const updateFeedById = (feedId, feed) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feeds/${feedId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, feed, headers);
};
