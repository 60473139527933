import commonUtil from "../common";
import { USER_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = USER_SERVER_URL;

export const generateMtzFaqSitemap = (params) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/sitemap/mtz-faqs`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, params, headers);
};
