import commonUtil from "../common";
import {
    NETWORK_SERVER_URL,
    SERVER_API_ALL,
} from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const createCoupon = coupon => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/coupons`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, coupon, headers);
}

export const deleteCouponById = couponId => {
    let method = "DELETE";
    let url = `${SERVER_URL}${SERVER_API_ALL}/coupons/${couponId}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}

export const getCouponById = couponId => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/coupons/${couponId}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}

export const getCoupons = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/coupons?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}

export const getCouponStats = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/coupons/stats?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}

export const updateCouponById = (couponId, params) => {
    let method = "PATCH";
    let url = `${SERVER_URL}${SERVER_API_ALL}/coupons/${couponId}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, params, headers);
}






