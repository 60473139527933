import commonUtil from "../common";
import { NETWORK_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const createCGSponsorPlanSubscription = (companyGroupSponsorPlanSubscription) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-plan-subscriptions`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, companyGroupSponsorPlanSubscription, headers);
};

export const updateCGSponsorPlanSubscriptionById = (companyGroupSponsorPlanSubscriptionId, update) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-plan-subscriptions/${companyGroupSponsorPlanSubscriptionId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, update, headers);
};

export const getCGSponsorPlanSubscriptionById = (companyGroupSponsorPlanSubscriptionId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-plan-subscriptions/${companyGroupSponsorPlanSubscriptionId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGSponsorPlanSubscriptions = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-plan-subscriptions?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGSponsorPlanSubscriptionStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-plan-subscriptions/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};