import commonUtil from "../common";
import {
  NETWORK_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const createFormBuilderFeedback = formBuilderFeedback => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/form-builder-feedbacks`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, formBuilderFeedback, headers);
}

export const getFormBuilderFeedbackById = formBuilderFeedbackId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/form-builder-feedbacks/${formBuilderFeedbackId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const deleteFormBuilderFeedbackById = formBuilderFeedbackId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/form-builder-feedbacks/${formBuilderFeedbackId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getFormBuilderFeedbacks = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/form-builder-feedbacks?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getFormBuilderFeedbackStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/form-builder-feedbacks/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}