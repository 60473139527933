import commonUtil from "../common";
import {
  NETWORK_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

const getCronEmails = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cron-emails?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const getCronEmailStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cron-emails/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const viewSampleCronEmailById = id => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cron-emails/${id}/view-sample`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const deleteCronEmails = filter => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cron-emails`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, filter, headers);
}

const sendCronEmails = filter => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cron-emails/deliveries`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, filter, headers);
}

export {
  getCronEmails,
  getCronEmailStats,
  viewSampleCronEmailById,
  deleteCronEmails,
  sendCronEmails
};