import commonUtil from "../common";
import {
  NETWORK_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const getMyReferralTrackingCode = () => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/tracking-codes/referral`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}
