import sessionStorageUtil from '../utils/sessionStorageUtil';

const logoutAll = redirectUrl => {
  localStorage.clear();
  window.location.href = "/login?redirectUrl=/me?justLoggedIn=true";
}

const removeLogin = userId => {
  let auth = localStorage.getItem("AUTH") || "{}";
  auth = JSON.parse(auth);
  auth.MTZ_LOGIN_TOKENS = auth.MTZ_LOGIN_TOKENS || [];
  auth.MTZ_LOGIN_TOKENS = auth.MTZ_LOGIN_TOKENS.filter(token =>
    Object.keys(token).length > 0 && Object.keys(token)[0] !== userId
  );

  if (auth.MTZ_LOGIN_TOKENS.length === 0)
    localStorage.removeItem("AUTH");
  else {
    auth = JSON.stringify(auth);
    localStorage.setItem("AUTH", auth);
  }
}

const switchLogin = id => {
  let tokens = getMTZLoginTokens();
  let switchingToken = tokens.find(token => {
    const userId = Object.keys(token)[0];
    if (userId === id)
      return token;
  });
  tokens = tokens.filter(token => {
    const userId = Object.keys(token)[0];
    return userId !== id;
  });
  tokens = [switchingToken, ...tokens];

  let auth = localStorage.getItem("AUTH") || "{}";
  auth = JSON.parse(auth);
  auth.MTZ_LOGIN_TOKENS = tokens;
  auth = JSON.stringify(auth);
  localStorage.setItem("AUTH", auth);
};

const setMTZLoginToken = (userId, token) => {
  let auth = localStorage.getItem("AUTH") || "{}";
  auth = JSON.parse(auth);
  auth.MTZ_LOGIN_TOKENS = auth.MTZ_LOGIN_TOKENS || [];

  const existingLogin = auth.MTZ_LOGIN_TOKENS.find(token => {
    const id = Object.keys(token)[0];
    return id === userId;
  });
  if (existingLogin)
    removeLogin(userId);

  auth.MTZ_LOGIN_TOKENS.unshift({ [userId]: token });
  auth = JSON.stringify(auth);
  localStorage.setItem("AUTH", auth);
};

const getMTZLoginTokens = () => {
  let auth = localStorage.getItem("AUTH");
  if (auth) {
    auth = JSON.parse(auth);
    return auth.MTZ_LOGIN_TOKENS || [];
  }
  return [];
};

const removeCurrentLogin = () => {
  let tokens = getMTZLoginTokens();
  console.log(tokens);
  if (!tokens) return;
  tokens.shift();
  let auth = localStorage.getItem('AUTH');
  auth = JSON.parse(auth);
  auth.MTZ_LOGIN_TOKENS = tokens;
  localStorage.setItem('AUTH', JSON.stringify(auth));
};

const updateMTZLoginToken = (userId, token) => {
  let auth = localStorage.getItem("AUTH") || "{}";
  auth = JSON.parse(auth);
  auth.MTZ_LOGIN_TOKENS = auth.MTZ_LOGIN_TOKENS || [];

  const tokenIndex = auth.MTZ_LOGIN_TOKENS.findIndex(token => Object.keys(token)[0] === userId);

  auth.MTZ_LOGIN_TOKENS[tokenIndex] = { [userId]: token };
  auth = JSON.stringify(auth);
  localStorage.setItem("AUTH", auth);
}

const httpclient = async (method, url, params = {}, headers = {}) => {
  const tokens = getMTZLoginTokens();
  let token = tokens && tokens.length > 0 && tokens[0];
  let vals = token ? Object.values(token) : null;
  let tokenStr = vals ? vals[0] : '';
  let userId = null;
  if (tokenStr) {
    let body = tokenStr.split('.')[1];
    userId = JSON.parse(atob(body)).userId;
  }

  const requestOptions = {
    method,
    headers: {
      ...headers,
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${tokenStr}`,
      "Access-Control-Allow-Origin": "*",
      "Tracking-Codes": sessionStorageUtil.get(sessionStorageUtil.NETWORK_SERVICE, sessionStorageUtil.TRACKING_CODES, 'urlTrackingCodes')
    },
    mode: 'cors',
  };

  if (params instanceof FormData) {
    delete requestOptions.headers["Content-Type"];
    requestOptions.headers.enctype = 'multipart/form-data';
    requestOptions.body = params;
  } else
    if (method.toUpperCase() !== "GET") {
      requestOptions.body = JSON.stringify(params);
    } else {
      if (url.includes('?undefined')) {
        url = url.split("?")[0];
      }
    }

  let res = await fetch(url, requestOptions);

  if (res.headers.get('content-type') === 'application/octet-stream')
    return res.blob();

  res = await res.text();
  if (res)
    res = JSON.parse(res);

  if (res.error) {
    switch (res.statusCode) {
      case 401:
        // window.location.href = `/logout?redirectUrl=${window.location.href}&statusCode=401`;
        alert(res.message);
        break;
      case 503:
        alert('Current login is invalid or expired, Logging out the current user...');
        removeCurrentLogin();
        window.location.href = '/login';
        break;
      case 400, 404:
        console.log("DEV ONLY: " + JSON.stringify(res));
        alert();
        alert(res.message);
        break;
      default:
        console.log("DEV ONLY: " + JSON.stringify(res));
        alert(res.message);
        break;
    }

    return; // must return null here, do not remove.
  }

  return res;
};

export default {
  httpclient,
  setMTZLoginToken,
  getMTZLoginTokens,
  switchLogin,
  removeLogin,
  logoutAll,
  updateMTZLoginToken,
};