import commonUtil from "../common";
import { NETWORK_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const createCompanyGroupPostFile = (companyGroupPostFile) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-files`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, companyGroupPostFile, headers);
};

export const deleteCompanyGroupPostFileById = (companyGroupPostFileId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-files/${companyGroupPostFileId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCompanyGroupPostFileById = (companyGroupPostFileId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-files/${companyGroupPostFileId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCompanyGroupPostFiles = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-files?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const getCompanyGroupPostFileStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-files/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const updateCompanyGroupPostFileById = (companyGroupPostFileId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-files/${companyGroupPostFileId}`;
  let headers = {};
  return commonUtil.httpclient(method, url, params, headers);
};
