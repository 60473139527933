import React from 'react';
import { BottomRightDiv, LimitedDiv } from './style.js';


import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import withAuth from '../../../../hocWrappers/withAuth';
import mtzApis from '../../../../services';
import MessageManager from "../../messages/MessageManager";
import RoomManager from '../../rooms/RoomManager';

const { chatService } = mtzApis;

function InstantChatManager({ me, newRoomEvent, newMessageEvent }) {
    let [active, setActive] = React.useState(null);
    let [unreadStats, setUnreadStats] = React.useState();

    React.useEffect(() => {
        init();
    }, []);

    const init = async () => {
        // load unreads
        let stats = await chatService.getMessageStats(new URLSearchParams(`notSeenBy=${me.userId}`));
        if (stats)
            setUnreadStats(stats);
    };

    // room events
    React.useEffect(() => {
        // if (!newRoomEvent.resource)
        //     setActive(null);
    }, [newRoomEvent]);

    // message events
    React.useEffect(() => {
        if (!newMessageEvent || !newMessageEvent.resource)
            return;
        let msg = newMessageEvent.resource;

        if (newMessageEvent.action === 'DELETED') {
            if (!msg.viewerIds.includes(me.userId))
                return;
            setUnreadStats(prev => ({ all: { count: prev.all.count <= 0 ? 0 : prev.all.count - 1 } }));
        }

        if (newMessageEvent.action === 'CREATED') {
            if (msg.viewerIds.includes(me.userId))
                return;
            setUnreadStats(prev => ({ all: { count: prev.all.count < 100 ? prev.all.count + 1 : prev.all.count } }));
        }

        if (newMessageEvent.action === 'UPDATED') {
            (async () => {
                let stats = await chatService.getMessageStats(new URLSearchParams(`notSeenBy=${me.userId}&limit=100`));
                setUnreadStats(stats);
            })();
        }
    }, [newMessageEvent]);

    return (
        <React.Fragment>
            {
                !me || !me.userId ?
                    "" :
                    <BottomRightDiv>
                        <div className="vw-25 d-flex flex-column">
                            <div className='d-flex'>
                                <button data-toggle='collapse' data-target='#collapse-instant-chat'
                                    className='btn btn-primary rounded-0'>
                                    Messages <span className='fa fa-message'></span>
                                </button>
                                {
                                    unreadStats && unreadStats.all && unreadStats.all.count > 0 &&
                                    <span><b style={{ padding: '0px 8px', position: 'relative', top: '-23px', left: '-5px' }}
                                        className='btn btn-danger rounded-circle mtz-b3 text-white'>
                                        {unreadStats.all.count < 100 ? unreadStats.all.count : '99+'}
                                    </b></span>
                                }

                                <span className='flex-fill'></span>

                                <span data-toggle='tooltip' data-title='aaa'>
                                    <NavLink to='/chat' className='ml-auto'>
                                        <button className='btn rounded-circle'>
                                            <span className='fa fa-external-link'></span>
                                        </button>
                                    </NavLink>
                                </span>
                            </div>

                            <div id='collapse-instant-chat'
                                className="collapse shadow bg-white mtz-rounded-16 flex-column">
                                {
                                    active &&
                                    <div className='mb-4'>
                                        <LimitedDiv>
                                            <button onClick={() => setActive()} className='btn text-primary'>
                                                <span className='fa fa-arrow-left mx-2'></span> Back to rooms
                                            </button>
                                            <MessageManager room={active} />
                                        </LimitedDiv>
                                    </div>
                                }

                                {
                                    !active &&
                                    <LimitedDiv>
                                        <RoomManager room={active} onChange={updated => setActive(updated)} />
                                    </LimitedDiv>
                                }
                            </div>
                        </div>
                    </BottomRightDiv>
            }
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    newMessageEvent: state.message_topic.new_uievent,
    newRoomEvent: state.room_topic.new_uievent
});

const InstantChatManagerWrapper = connect(
    mapStateToProps
)(InstantChatManager);

export default withAuth(InstantChatManagerWrapper);