import commonUtil from "../common";
import {
  USER_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = USER_SERVER_URL;

export const getUserExtraQuestionAnswers = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/user-extra-question-answers?${decodeURIComponent(queries)}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getUserExtraQuestionAnswerById = (userExtraQuestionAnswerId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/user-extra-question-answers/` + userExtraQuestionAnswerId;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, userExtraQuestionAnswerId, headers);
};

export const deleteUserExtraQuestionAnswerById = (userExtraQuestionAnswerId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/user-extra-question-answers/${userExtraQuestionAnswerId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const createUserExtraQuestionAnswer = (newUserExtraQuestionAnswer) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/user-extra-question-answers`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, newUserExtraQuestionAnswer, headers);
};

export const updateUserExtraQuestionAnswerById = (userExtraQuestionAnswerId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/user-extra-question-answers/${userExtraQuestionAnswerId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};
