import React from "react";
import Footer from "../ParentLayout2/Footer";
import './index.css';

class WithOneColumnLayout extends React.Component {

    
    render() {
        return (
            <div className="flex-grow-1 d-flex flex-column justify-content-center WithOneColumnLayout">
                <div className="mx-auto WithOneColumnLayout-content">
                    { this.props.center && <this.props.center /> } 
                </div>
            
                <div className="flex-grow-1"></div>

                <br/>
                
                <Footer />
            </div>
        );
    };
}

export default WithOneColumnLayout;