import { COMPANY_SERVER_URL } from "../config";

import {
  createResourceLimit,
  deleteResourceLimitById,
  getResourceLimitById,
  getResourceLimitStats,
  getResourceLimits,
  updateResourceLimitById
} from "./resourceLimitApi";


import * as companyExtraQuestionAnswerApi from './companyExtraQuestionAnswerApi';
import * as companyExtraQuestionApi from './companyExtraQuestionApi';
import * as companyExtraQuestionChoiceApi from './companyExtraQuestionChoiceApi';
import * as companyMemberApi from './companyMemberApi';
import * as companyMemberInviteApi from './companyMemberInviteApi';
import * as productApi from './productApi';
import * as productUploadEventApi from './productUploadEventApi';
import * as productWatchlistApi from './productWatchlistApi';
import * as rfiApi from './rfiApi';
import * as rfiType from './rfiRequestTypeApi';
import * as rfqApi from './rfqApi';
import * as rfqGroupApi from './rfqGroupApi';
import * as rfqImageApi from './rfqImageApi';
import * as rfqSearchApi from './rfqSearchApi';
import * as syncedMtzPlanSubscriptionApi from './syncedMtzPlanSubscriptionApi';
import * as tagAlertApi from './tagAlertApi';

import {
  createPrice,
  deletePriceById,
  deletePrices,
  getPriceById,
  getPrices,
  updatePriceById,
} from "./priceApi";



import {
  generateCompanySiteMap,
  generateProductSiteMap,
} from "./siteMapApi";

import * as coProfilePercentApi from './coProfilePercentApi';
import * as companyApi from "./companyApi";
import { getCompanySearchStats, getCompanySearches } from "./companySearchApi";
import * as productImageApi from './productImageApi';
import * as productSearchApi from "./productSearchApi";
import * as resourceSyncApi from './resourceSyncApi';
import * as syncedUserApi from './syncedUserApi';

import {
  createCategory,
  deleteCategories,
  deleteCategoryById,
  getCategories,
  getCategoryById,
  getCategoryCount,
  updateCategoryById
} from "./categoryApi";

import {
  createCompanyWatchlist,
  deleteCompanyWatchlistById,
  deleteCompanyWatchlists,
  getCompanyWatchlistById,
  getCompanyWatchlistStats,
  getCompanyWatchlists,
  shareCompanyWatchlist,
  updateCompanyWatchlistById,
} from "./companyWatchlistApi";

import {
  createProductMenu,
  deleteProductMenuById,
  deleteProductMenus,
  getProductMenuById,
  getProductMenuStats,
  getProductMenus,
  updateProductMenuById
} from "./productMenuApi";

import { getEmployeeRangeCodes } from "./employeeRangeCodes";

import { getPhoneCode, getPhoneCodeCountries } from "./phoneCodeApi";

import {
  createCompanyNote,
  deleteCompanyNoteById,
  getCompanyNoteById,
  getCompanyNoteStats,
  getCompanyNotes,
  updateCompanyNoteById
} from "./companyNoteApi";

import {
  createCompanyFile,
  deleteCompanyFileById,
  getCompanyFileById,
  getCompanyFileStats,
  getCompanyFiles,
  updateCompanyFileById,
} from "./companyFile";

import {
  createNoteFile,
  deleteNoteFileById,
  getNoteFileById,
  getNoteFileStats,
  getNoteFiles,
  updateNoteFileById
} from "./noteFileApi";

import {
  createCompanyName,
  deleteCompanyNameById,
  getCompanyNameById,
  getCompanyNameStats,
  getCompanyNames,
  updateCompanyNameById
} from "./companyNameApi";

import {
  createCompanyPrivateFile,
  deleteCompanyPrivateFileById,
  getCompanyPrivateFileById,
  getCompanyPrivateFileStats,
  getCompanyPrivateFiles,
  updateCompanyPrivateFileById,
  viewCompanyPrivateFileById
} from "./companyPrivateFileApi";

import {
  acceptFileAccess,
  deleteFileAccess,
  requestFileAccess
} from "./fileAccessApi";

import * as adCampaignApi from "./adCampaignApi";
import * as adCampaignClickChargeApi from "./adCampaignClickChargeApi";
import * as adCampaignDepositApi from "./adCampaignDepositApi";
import * as bannerAdApi from "./bannerAdApi";
import * as bannerAdSearchApi from "./bannerAdSearchApi";
import * as companyActionApi from "./companyActionApi";
import * as companyAdKeywordApi from "./companyAdKeywordApi";
import * as companyAdKeywordSearchApi from "./companyAdKeywordSearchApi";
import * as companyMtzFileApi from "./companyMtzFileApi";
import * as companyMtzFileRequestApi from "./companyMtzFileRequestApi";
import * as companySearchReportApi from "./companySearchReportApi";
import * as productActionApi from "./productActionApi";
import * as productSearchReportApi from "./productSearchReportApi";
import * as textAdApi from "./textAdApi";
import * as textAdSearchApi from "./textAdSearchApi";

const getBackendHost = () => COMPANY_SERVER_URL;

export default {
  updateCompanyWatchlistById,
  getCompanyWatchlistById,
  getCompanyWatchlists,
  getCompanyWatchlistStats,
  createCompanyWatchlist,
  deleteCompanyWatchlistById,
  deleteCompanyWatchlists,
  shareCompanyWatchlist,

  getCategories,
  // getCategoryStats,
  getCategoryCount,
  getCategoryById,
  deleteCategoryById,
  deleteCategories,
  updateCategoryById,
  createCategory,

  getPriceById,
  getPrices,
  createPrice,
  updatePriceById,
  deletePriceById,
  deletePrices,

  getCompanySearches,
  getCompanySearchStats,

  getBackendHost,

  getProductMenus,
  getProductMenuById,
  createProductMenu,
  deleteProductMenuById,
  deleteProductMenus,
  updateProductMenuById,
  getProductMenuStats,

  getEmployeeRangeCodes,

  getPhoneCode,
  getPhoneCodeCountries,

  createCompanyNote,
  deleteCompanyNoteById,
  getCompanyNoteById,
  getCompanyNotes,
  getCompanyNoteStats,
  updateCompanyNoteById,

  createCompanyFile,
  updateCompanyFileById,
  deleteCompanyFileById,
  getCompanyFileById,
  getCompanyFileStats,
  getCompanyFiles,

  generateProductSiteMap,
  generateCompanySiteMap,

  getNoteFiles,
  getNoteFileStats,
  getNoteFileById,
  createNoteFile,
  deleteNoteFileById,
  updateNoteFileById,

  getCompanyNameById,
  getCompanyNames,
  getCompanyNameStats,
  updateCompanyNameById,
  deleteCompanyNameById,
  createCompanyName,

  getCompanyPrivateFileById,
  getCompanyPrivateFileStats,
  deleteCompanyPrivateFileById,
  createCompanyPrivateFile,
  updateCompanyPrivateFileById,
  getCompanyPrivateFiles,
  viewCompanyPrivateFileById,

  requestFileAccess,
  acceptFileAccess,
  deleteFileAccess,

  createResourceLimit,
  getResourceLimitById,
  getResourceLimits,
  getResourceLimitStats,
  updateResourceLimitById,
  deleteResourceLimitById,

  ...companyExtraQuestionApi,
  ...companyExtraQuestionChoiceApi,
  ...companyExtraQuestionAnswerApi,
  ...syncedUserApi,
  ...coProfilePercentApi,
  ...productWatchlistApi,
  ...resourceSyncApi,
  ...companyAdKeywordApi,
  ...companyAdKeywordSearchApi,
  ...productUploadEventApi,
  ...companyActionApi,
  ...productActionApi,
  ...companySearchReportApi,
  ...productSearchReportApi,
  ...companyMemberApi,
  ...companyMemberInviteApi,
  ...adCampaignApi,
  ...textAdApi,
  ...textAdSearchApi,
  ...bannerAdApi,
  ...bannerAdSearchApi,
  ...adCampaignDepositApi,
  ...adCampaignClickChargeApi,
  ...companyMtzFileApi,
  ...companyMtzFileRequestApi,
  ...rfqApi,
  ...rfiApi,
  ...rfiType,
  ...rfqImageApi,
  ...rfqSearchApi,
  ...rfqGroupApi,
  ...syncedMtzPlanSubscriptionApi,
  ...productSearchApi,
  ...productImageApi,
  ...companyApi,
  ...productApi,
  ...tagAlertApi
};
