import commonUtil from "../common";
import { NETWORK_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

export const testSchemaLayout = (params) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-schema-layouts/test`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, params, headers);
};

export const createEmailSchemaLayout = (emailSchemaLayout) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-schema-layouts`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, emailSchemaLayout, headers);
};

export const deleteEmailSchemaLayoutById = (emailSchemaLayoutId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-schema-layouts/${emailSchemaLayoutId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getEmailSchemaLayoutById = (emailSchemaLayoutId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-schema-layouts/${emailSchemaLayoutId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getEmailSchemaLayouts = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-schema-layouts?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getEmailSchemaLayoutStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-schema-layouts/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const updateEmailSchemaLayoutById = (emailSchemaLayoutId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-schema-layouts/${emailSchemaLayoutId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};
