import commonUtil from "../common";
import {
  NETWORK_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = NETWORK_SERVER_URL;

const createConnection = connection => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/connections`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, connection, headers);
}

const resendConnectionRequest = connectionReminderCreateParams => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/connections/reminder`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, connectionReminderCreateParams, headers);
}

const deleteConnectionById = connId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/connections/${connId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const deleteConnections = queries => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/connections?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers, true, false);
}

const getConnectionById = connId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/connections/${connId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const getConnections = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/connections?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers, true, false);
}

const getMyConnections = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/connections?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers, true, false);
}

const getConnectionStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/connections/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers, true, false);
}

const updateConnectionById = (connId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/connections/${connId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}

export {
  createConnection,
  resendConnectionRequest,
  deleteConnectionById,
  deleteConnections,
  updateConnectionById,
  getConnections,
  getMyConnections,
  getConnectionStats,
  getConnectionById
}