import React from "react";

import { NavLink, withRouter } from "react-router-dom";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import CropFreeIcon from '@material-ui/icons/CropFree';
import FileCopy from "@material-ui/icons/FileCopy";
import MenuBookIcon from '@material-ui/icons/MenuBook';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ReceiptIcon from "@material-ui/icons/Receipt";
import ShopIcon from '@material-ui/icons/Shop';
import Alert from "@material-ui/lab/Alert";
import QRCode from 'qrcode';
import AuthContext from "../../AuthContext";
import mtzApis from "../../services";
import * as ICON_CONSTANTS from "../../utils/constants/icons";
import { PlanButton } from "../ParentLayout2/Header";
import "./style.css";

const { companyService, paymentService, networkService, metricService, userService } = mtzApis;

function MainMenu(props) {
  let pathname = window.location.pathname;
  const me = React.useContext(AuthContext);
  let [myCompany, setMyCompany] = React.useState(null);
  let [userConfirmStatReader, setUserConfirmStatReader] = React.useState(null);
  let [companyQRopen, setCompanyQROpen] = React.useState(false);
  let [companyQRcode, setCompanyQRCode] = React.useState(null);
  let [userQROpen, setUserQROpen] = React.useState(false);
  let [userQRCode, setUserQRCode] = React.useState(null);
  let [bio, setBio] = React.useState(null);
  let [refTc, setRefTc] = React.useState();

  React.useEffect(() => {
    (async () => {
      if (!me || JSON.stringify(me) === '{}' || !me.userId) return;
      else {
        bio = await userService.getMyUserBio();
        if (bio)
          setBio(bio);
      }

      refTc = await networkService.getMyReferralTrackingCode();
      if (refTc) setRefTc(refTc);

      myCompany = await companyService.getMyCompany();
      if (myCompany) setMyCompany(myCompany);

      userConfirmStatReader = await metricService.getUserConfirmStatReaders();
      if (userConfirmStatReader && userConfirmStatReader.length === 1)
        setUserConfirmStatReader(userConfirmStatReader[0]);
    })();
  }, []);

  const manageBiling = async () => {
    mtzApis.startSpinningIcon();
    let customerPortalSession = await paymentService.createStripeCustomerPortalSession({
      returnUrl: `${window.location.origin}/me`,
    });
    mtzApis.stopSpinningIcon();

    if (customerPortalSession) window.location.href = customerPortalSession.url;
    else window.location.href = "/pricing";
  };

  const isNetworkSelected = () => {
    if (
      pathname === "/me/network" ||
      pathname === "/me/connections" ||
      pathname.includes("/me/groups") ||
      pathname === "/me/followings" ||
      pathname === "/me/company-watchlists" ||
      pathname === "/me/product-watchlists"
    ) {
      return true;
    } else return false;
  };

  const generateCompanyQRCode = () => {
    let opts = {
      errorCorrectionLevel: 'H',
      type: 'image/jpeg',
      quality: 1,
      width: 300,
    }

    QRCode.toDataURL(`${window.location.protocol}//${window.location.host}/profile/${myCompany.profileName}${!refTc ? '' : `?fromQR=true&tc=${refTc.id}`}`, opts, function (err, url) {
      if (err) throw err
      setCompanyQRCode(url);
    })
  }

  const generateUserQRCode = () => {
    let opts = {
      errorCorrectionLevel: 'H',
      type: 'image/jpeg',
      quality: 1,
      width: 300,
    }

    QRCode.toDataURL(`${window.location.protocol}//${window.location.host}/users/${bio.profileName}${!refTc ? '' : `?fromQR=true&tc=${refTc.id}`}`, opts, function (err, url) {
      if (err) throw err
      setUserQRCode(url);
    })
  }

  return (
    <div
      className="mtz-rounded-16 bg-white w-100 p-3"
    >
      <div className="w-100 btn-group-toggle overflow-auto d-flex flex-column mtz-gap-8">
        <PlanButton />

        <div
          className={'d-flex flex-row mtz-gap-4 ' + (pathname === "/me" ? "btn btn-secondary" : "btn")}
          onClick={() => (window.location.href = `/me`)}
          style={{ borderRadius: "8px", padding: "8px 16px" }}
        >
          <div>
            <img
              src={
                pathname === "/me"
                  ? ICON_CONSTANTS.SPACE_DASHBOARD_WHITE_ICON
                  : ICON_CONSTANTS.SPACE_DASHBOARD_BLACK_ICON
              }
              height="20px"
              width="20px"
            />
          </div>

          <div>Overview</div>
        </div>

        <NavLink to={`/me/get-started`}>
          <div
            className={"d-flex flex-row mtz-gap-4 " + (pathname.includes(`/me/get-started`) ? "btn btn-secondary" : "btn")}
          >
            <BubbleChartIcon
              style={{
                color: pathname.includes(`/me/get-started`) ? "white" : "black",
                height: "20px",
                width: "20px",
              }}
            />

            <div className="text-nowrap">
              Get Started
            </div>
          </div>
        </NavLink>

        {
          bio &&
          <NavLink to={`/users/${bio.profileName}`}>
            <div
              className={
                "d-flex flex-row mtz-gap-4 " + (pathname.includes(`/users/${bio.profileName}`) ? "btn btn-secondary" : "btn")
              }
            >
              <div>
                <img
                  src={
                    pathname.includes(`/users/${bio.profileName}`)
                      ? ICON_CONSTANTS.ACCOUNT_CIRCLE_WHITE_ICON
                      : ICON_CONSTANTS.ACCOUNT_CIRCLE_BLACK_ICON
                  }
                  height="20px"
                  width="20px"
                />
              </div>
              <div className="text-nowrap">
                My Profile
              </div>
            </div>
          </NavLink>
        }

        {myCompany && (
          <>
            <NavLink to={`/profile/${myCompany.profileName}`}>
              <div
                className={
                  "d-flex flex-row mtz-gap-4 " + (pathname.includes(`/profile/${myCompany.profileName}`) ? "btn btn-secondary" : "btn")
                }
              >
                <div>
                  <img
                    src={
                      pathname.includes(`/users/${myCompany.profileName}`)
                        ? ICON_CONSTANTS.COMPANY_WHITE_ICON
                        : ICON_CONSTANTS.COMPANY_BLACK_ICON
                    }
                    height="20px"
                    width="20px"
                  />
                </div>
                <div className="text-nowrap">
                  My Company
                </div>
              </div>
            </NavLink>
            <NavLink to={`/me/network/events/tickets/my-orders`}>
              <div
                className={
                  "d-flex flex-row mtz-gap-4 " + (pathname.includes(`/me/network/events/tickets/my-orders`) ? "btn btn-outline-secondary" : "btn")
                }
              >
                <span className="fa fa-ticket"></span>
                <div className="text-nowrap">
                  My Ticket Orders
                </div>
              </div>
            </NavLink>
            <NavLink to={`/me/notifications`}>
              <div
                className={"d-flex flex-row mtz-gap-4 " + (pathname.includes(`/me/notifications`) ? "btn btn-secondary" : "btn")}
              >
                <NotificationsIcon
                  style={{
                    color: pathname.includes(`/me/notifications`) ? "white" : "black",
                    height: "20px",
                    width: "20px",
                  }}
                />
                <div className="text-nowrap">
                  Message Center
                </div>
              </div>
            </NavLink>

            <NavLink to={`#`} onClick={() => { generateCompanyQRCode(); setCompanyQROpen(true) }}>
              <div
                className={
                  "d-flex flex-row mtz-gap-4 btn"
                }
              >
                <CropFreeIcon
                  style={{
                    color: "black",
                    height: "20px",
                    width: "20px",
                  }}
                />
                <div className="text-nowrap">
                  Company QR
                </div>
              </div>
            </NavLink>

            <NavLink to={`#`} onClick={() => { generateUserQRCode(); setUserQROpen(true) }}>
              <div
                className={
                  "btn d-flex flex-row"
                }
              >
                <CropFreeIcon
                  style={{
                    color: "black",
                    height: "20px",
                    width: "20px",
                  }}
                />
                <div className="text-nowrap">
                  User QR
                </div>
              </div>
            </NavLink>

            <NavLink to={`/me/mtz-files`}>
              <div
                className={
                  "d-flex flex-row btn mtz-gap-4 aligns-item-center"
                }
              >
                <FileCopy
                  style={{
                    color: "black",
                    height: "20px",
                    width: "20px",
                  }}
                />
                <div className="text-nowrap">
                  My Files
                </div>
              </div>
            </NavLink>

            <NavLink to={`/me/tag-alerts`}>
              <div
                className={
                  "d-flex flex-row btn mtz-gap-4 aligns-item-center"
                }
              >
                <span className="fa fa-tags"></span>
                <div className="text-nowrap">
                  Tag Alerts
                </div>
              </div>
            </NavLink>

            <NavLink to={`/me/extra-info`}>
              <div
                className={"d-flex flex-row mtz-gap-4 " + (pathname.includes(`/me/extra-info`) ? "btn btn-secondary" : "btn")}
              >
                <BubbleChartIcon
                  style={{
                    color: pathname.includes(`/me/extra-info`) ? "white" : "black",
                    height: "20px",
                    width: "20px",
                  }}
                />
                <div className="text-nowrap">
                  Extra Info
                </div>
              </div>
            </NavLink>

            <NavLink to={`/me/products`}>
              <div
                className={
                  "d-flex flex-row mtz-gap-4 " + (pathname.includes("/me/products") || pathname.includes("/me/product-menus")
                    ? "btn btn-secondary"
                    : "btn")
                }
              >
                <div>
                  <img
                    src={
                      pathname.includes("/me/products") || pathname.includes("/me/product-menus")
                        ? ICON_CONSTANTS.CATEGORY_WHITE_ICON
                        : ICON_CONSTANTS.CATEGORY_BLACK_ICON
                    }
                    height="20px"
                    width="20px"
                  />
                </div>
                <div>Products</div>
              </div>
            </NavLink>
          </>
        )}

        <NavLink to={`/me/network`}>
          <div
            className={"d-flex flex-row mtz-gap-4 " + (isNetworkSelected() ? "btn btn-secondary" : "btn")}
          >
            <div>
              <img
                src={
                  isNetworkSelected()
                    ? ICON_CONSTANTS.HUB_WHITE_ICON
                    : ICON_CONSTANTS.HUB_BLACK_ICON
                }
                height="20px"
                width="20px"
              />
            </div>
            <div>Network</div>
          </div>
        </NavLink>

        <NavLink to={`/me/crm/company-notes`}>
          <div
            className={"d-flex flex-row mtz-gap-4 " + (pathname.includes("/me/crm/company-notes") ? "btn btn-secondary" : "btn")}
          >
            <div>
              <NoteAddIcon
                style={{
                  color: pathname.includes("/me/crm/company-notes") ? "white" : "black",
                  height: "20px",
                  width: "20px",
                }}
              />
            </div>
            <div pl={1} >Company&nbsp;Notes</div>
          </div>
        </NavLink>

        <NavLink to={`/me/crm`}>
          <div
            className={"d-flex flex-row mtz-gap-4 " + (pathname === "/me/crm" ? "btn btn-secondary" : "btn")}
          >
            <div>
              <img
                src={
                  pathname === "/me/crm"
                    ? ICON_CONSTANTS.SUPPORT_AGENT_WHITE_ICON
                    : ICON_CONSTANTS.SUPPORT_AGENT_BLACK_ICON
                }
                height="20px"
                width="20px"
              />
            </div>
            <div>CRM</div>
          </div>
        </NavLink>

        <NavLink to={`/me/rfqs`}>
          <div
            className={
              "d-flex flex-row mtz-gap-4 " + (pathname.includes("/me/rfqs") || pathname.includes("/me/quotes")
                ? "btn btn-secondary"
                : "btn")
            }
          >
            <div>
              <img
                src={
                  pathname.includes("/me/rfqs") || pathname.includes("/me/quotes")
                    ? ICON_CONSTANTS.RFQ_WHITE_ICON
                    : ICON_CONSTANTS.RFQ_BLACK_ICON
                }
                height="20px"
                width="20px"
              />
            </div>
            <div>RFQs</div>
          </div>
        </NavLink>

        <NavLink to={`/me/billing`}>
          <div
            className={"d-flex flex-row mtz-gap-4 " + "align-items-center " + (pathname.includes("/me/billing") ? "btn btn-secondary" : "btn")}
          >
            <div>
              <ReceiptIcon
              />
            </div>
            <div>Billing</div>
          </div>
        </NavLink>

        <NavLink to={`/me/advertisement`}>
          <div
            className={"d-flex flex-row mtz-gap-4 " + (pathname.includes("/me/advertisement") ? "btn btn-secondary" : "btn")}
          >
            <div>
              <ShopIcon
                style={{
                  color: pathname.includes("/me/advertisement") ? "white" : "black",
                  height: "20px",
                  width: "20px",
                }}
              />
            </div>
            <div>Advertisement</div>
          </div>
        </NavLink>
        <NavLink to={`/pricing`}>
          <div
            className={"d-flex flex-row mtz-gap-4 " + (pathname.includes("/pricing") ? "btn btn-secondary" : "btn")}
          >
            <div>
              <MenuBookIcon
                style={{
                  color: pathname.includes("/pricing") ? "white" : "black",
                  height: "20px",
                  width: "20px",
                }}
              />
            </div>
            <div>Plans&nbsp;&&nbsp;Pricing</div>
          </div>
        </NavLink>

        <NavLink to={`/me/account`} className="mt-2 border-top">
          <div
            className={"d-flex flex-row mtz-gap-4 " + "btn"}
          >
            <div>
              <img src={ICON_CONSTANTS.SETTINGS_BLACK_ICON} height="20px" width="20px" />
            </div>
            <div>
              <span
                style={{
                  fontFamily: "Satoshi",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "22px",
                  letterSpacing: "0.01em",
                  color: "#000000",
                }}
              >
                Settings
              </span>
            </div>
          </div>
        </NavLink>
      </div>

      {myCompany && companyQRcode &&
        <Dialog open={companyQRopen} onClose={() => setCompanyQROpen(false)} maxWidth="sm" fullWidth>
          <DialogTitle>Company QR code</DialogTitle>
          <DialogContent dividers>
            <Alert severity="info" className="w-100">
              You can share the QR code with businesses to visit your company.
            </Alert>

            <div className="text-center">
              <img src={companyQRcode} />
            </div>

            <hr />
            <div>
              Having issue to scan the QR code? Just copy the link below to share:
              <div className="alert alert-primary text-break">
                {`${window.location.protocol}//${window.location.host}/profile/${myCompany.profileName}${!refTc ? '' : `?fromQR=true&tc=${refTc.id}`}`}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <button className="btn btn-light" onClick={() => setCompanyQROpen(false)}>
              Close
            </button>

            <a className="btn btn-primary text-white" href={companyQRcode} download="qrcode.png">
              Download QR
            </a>
          </DialogActions>
        </Dialog>
      }

      {bio && userQRCode &&
        <Dialog open={userQROpen} onClose={() => setUserQROpen(false)} maxWidth="sm" fullWidth>
          <DialogTitle>User QR code</DialogTitle>
          <DialogContent dividers>
            <Alert severity="info" className="w-100">
              You can share the QR code with people to visit your user profile.
            </Alert>

            <div className="text-center">
              <img src={userQRCode} />
            </div>

            <hr />
            <div>
              Having issue to scan the QR code? Just copy the link below to share:
              <div className="alert alert-primary text-break">
                {`${window.location.protocol}//${window.location.host}/users/${bio.profileName}${!refTc ? '' : `?fromQR=true&tc=${refTc.id}`}`}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <button className="btn btn-light" onClick={() => setUserQROpen(false)}>
              Close
            </button>

            <a className="btn btn-primary text-white" href={userQRCode} download="qrcode.png">
              Download QR
            </a>
          </DialogActions>
        </Dialog>
      }
    </div>
  );
}

export default withRouter(MainMenu);
